import React, { Component } from 'react';
import {
  getUserData,
  getRefreshToken,
  getTimeout,
  setToken,
  setRefreshToken
} from 'utils/auth';
import PropTypes from 'prop-types';

const timeoutDisplayText = process.env.REACT_APP_TIMEOUT_TEXT;
const intervalTime = 15 * 1000;
const warningTime = 90;

const props = {
  alert: PropTypes.object.isRequired
};

class SessionTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshing: false,
      jwtExpirationCheckInterval: null,
      logoutTimeout: null,
      hasShownTimer: false,
      alertShown: null,
      hasShownExpired: false,
      expiredAlert: null
    };
  }

  componentDidMount() {
    this.openModalIfExpiringSoon();

    this.setState({
      jwtExpirationCheckInterval: setInterval(
        this.openModalIfExpiringSoon,
        intervalTime
      )
    });
  }

  componentWillUnmount() {
    const { jwtExpirationCheckInterval } = this.state;

    if (jwtExpirationCheckInterval) {
      clearInterval(jwtExpirationCheckInterval);
    }
  }

  openModalIfExpiringSoon = () => {
    const tokenExpiration = this.tokenExpiration();
    const {
      refreshing,
      hasShownTimer,
      hasShownExpired,
      logoutTimeout
    } = this.state;

    const now = new Date().getTime() / 1000;

    const expiringIn = tokenExpiration && tokenExpiration - now;
    const isExpired = tokenExpiration && expiringIn < 0;
    const isExpiringSoon = tokenExpiration && expiringIn <= warningTime;

    if (tokenExpiration && isExpired) {
      this.logout();
    } else if (tokenExpiration && isExpiringSoon) {
      this.showExpiringAlert();

      if (!logoutTimeout) {
        this.setState({
          logoutTimeout: setTimeout(() => this.logout(), expiringIn * 1000)
        });
      }
    }

    if (tokenExpiration && !isExpired && !isExpiringSoon && !refreshing) {
      if (hasShownTimer) {
        this.clearExpiringAlert();
      }
      if (hasShownExpired) {
        this.clearLoggedOutAlert();
      }
    }
  };

  tokenExpiration = () => {
    const userData = getUserData();

    return userData && userData.exp;
  };

  continueSession = () => {
    const { refreshToken } = this.props;
    const { refreshing, logoutTimeout } = this.state;

    if (refreshing) {
      return;
    }

    this.setState({ refreshing: true });

    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
      this.setState({ logoutTimeout: null });
    }

    refreshToken(getRefreshToken()).then(response => {
      setToken(response.token);
      setRefreshToken(response.refresh_token);

      this.clearExpiringAlert();
      this.setState({ refreshing: false });
    });
  };

  logout = () => {
    const { logout } = this.props;
    const { logoutTimeout } = this.state;

    this.clearExpiringAlert();

    this.showLoggedOutAlert();

    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
      this.setState({
        logoutTimeout: null
      });
    }

    logout();
  };

  showExpiringAlert = () => {
    const { refreshing, hasShownTimer } = this.state;

    if (!refreshing && !hasShownTimer) {
      this.setState({
        hasShownTimer: true
      });

      const alert = this.props.alert.show(
        <div>
          Your session is about to expire. Please click the "Continue Session"
          button to add {timeoutDisplayText} to your session.
          <button className="btn btn-primary" onClick={this.continueSession}>
            Continue Session
          </button>
        </div>,
        {
          timeout: 0
        }
      );

      this.setState({ alertShown: alert });
    }
  };

  clearExpiringAlert = () => {
    const { alert } = this.props;

    alert.remove(this.state.alertShown);

    this.setState({
      alertShown: null,
      hasShownTimer: false
    });
  };

  showLoggedOutAlert = () => {
    const { hasShownExpired } = this.state;

    if (!hasShownExpired) {
      this.setState({ hasShownExpired: true });

      const alert = this.props.alert.show(
        <div>You have been logged out due to inactivity.</div>,
        {
          timeout: 0
        }
      );

      this.setState({ expiredAlert: alert });
    }
  };

  clearLoggedOutAlert = () => {
    const { alert } = this.props;

    alert.remove(this.state.expiredAlert);

    this.setState({
      expiredAlert: null,
      hasShownExpired: false
    });
  };

  render() {
    return null;
  }
}

SessionTimer.propTypes = props;

export default SessionTimer;
