export const UPDATE_RISK_VALUES = 'APP/UPDATE_RISK_VALUES';
export const ADD_ACCOUNT = 'APP/ADD_ACCOUNT';
export const ADD_ACCOUNT_RISK_VALUE = 'APP/ADD_ACCOUNT_RISK_VALUE';
export const ADD_RISK_TYPES = 'APP/ADD_RISK_TYPES';
export const RESET = 'APP/RESET';
export const ADD_BROKER_LIMITS = 'APP/ADD_BROKER_LIMITS';

export const reset = () => ({
  type: RESET
});

export const updateRiskValues = values => ({
  type: UPDATE_RISK_VALUES,
  payload: values
});

export const addBrokerLimits = values => ({
  type: ADD_BROKER_LIMITS,
  payload: values
});

export const addRiskTypes = values => ({
  type: ADD_RISK_TYPES,
  payload: values
});

export const addAccount = account => ({
  type: ADD_ACCOUNT,
  payload: account
});

export const addAccountRiskValue = (account, risk_value) => ({
  type: ADD_ACCOUNT_RISK_VALUE,
  payload: {
    account: account,
    risk_value: risk_value
  }
});

const initialState = {
  accountsLoaded: false,
  accounts: [],
  initialFormValues: {},
  newFormValues: [],
  riskTypes: [],
  riskTypesLoaded: false,
  brokerLimits: [],
  brokerLimitsLoaded: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;

    case UPDATE_RISK_VALUES:
      return {
        ...state,
        newFormValues: action.payload
      };

    case ADD_BROKER_LIMITS:
      return {
        ...state,
        brokerLimits: action.payload,
        brokerLimitsLoaded: true
      };

    case ADD_ACCOUNT:
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
        accountsLoaded: true
      };

    case ADD_ACCOUNT_RISK_VALUE:
      return {
        ...state,
        initialFormValues: {
          ...state.initialFormValues,
          ...{
            [`${action.payload.account.id}_${
              action.payload.risk_value.risk_type.id
            }`]: parseFloat(action.payload.risk_value.value)
          }
        }
      };

    case ADD_RISK_TYPES:
      return {
        ...state,
        riskTypes: action.payload,
        riskTypesLoaded: true
      };

    default:
      return state;
  }
};

export default reducer;
