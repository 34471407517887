import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';
import { XYPlot, LineSeries } from 'react-vis';
import { LARGE_GRAPH_HEIGHT } from 'components/withGraph';

class PricesGraph2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      candleData: null,
      dataPoint: null,
      showToolTip: false
    };

    this.clearTooltip = this.clearTooltip.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
  }

  clearTooltip() {
    this.setState({
      showTooltip: false,
      dataPoint: null
    });
  }

  showTooltip(dataPoint) {
    this.setState({
      showTooltip: true,
      dataPoint: dataPoint
    });
  }

  render() {
    const {
      width,
      timeFrame,
      yAxis,
      colors,
      vwapPriceEnabled,
      averagePriceEnabled
    } = this.props;

    const { dataSource7_averagePrice, dataSource7_VWAPPrice } = this.props;

    return (
      <XYPlot
        height={LARGE_GRAPH_HEIGHT}
        width={width}
        margin={{ left: 45, right: 20, top: 10, bottom: 75 }}
        yDomain={yAxis}
        xDomain={[timeFrame.min, timeFrame.max]}
        yBaseValue={yAxis[0]}
        className="graph"
      >
        {/* 1. Average Price vs Activity Time */}
        {averagePriceEnabled && (
          <LineSeries color={colors[0]} data={dataSource7_averagePrice} />
        )}

        {/* 2. VWAP Price vs Activity Time */}
        {vwapPriceEnabled && (
          <LineSeries
            strokeStyle="dashed"
            color={colors[1]}
            data={dataSource7_VWAPPrice}
          />
        )}
        {/*Needed to prevent graph from hiding when all graphs are disabled.*/}
        <LineSeries data={[{ x: 0, y: 0 }, { x: 0, y: 0 }]} />
      </XYPlot>
    );
  }
}

PricesGraph2.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired,
  dataSource7_averagePrice: PropTypes.array.isRequired,
  dataSource7_VWAPPrice: PropTypes.array.isRequired,
  averagePriceEnabled: PropTypes.bool.isRequired,
  vwapPriceEnabled: PropTypes.bool.isRequired
};

export default PricesGraph2;
