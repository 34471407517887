import React from 'react';
import PropTypes from 'prop-types';
import RiskHeaders from './RiskHeaders';
import Accounts from './Accounts';
import { reduxForm } from 'redux-form';
import Limits from './Limits';
import ClientName from '../../../components/ClientName';
import '../Edit.css';
import { Col } from 'reactstrap';

function EditRisk({
  accounts,
  riskTypes,
  handleSubmit,
  onSubmit,
  limits,
  onCancel,
  dirty
}) {
  return (
    <Col md={{ size: 8, offset: 2 }} className="edit-page">
      <ClientName />
      <form onSubmit={handleSubmit(onSubmit)}>
        <RiskHeaders riskTypes={riskTypes} />
        <Limits limits={limits} />
        <Accounts accounts={accounts} />

        <div className="actions">
          <button
            className="btn btn-danger"
            type="button"
            disabled={!dirty}
            onClick={onCancel}
          >
            Cancel
          </button>
          <button className="btn btn-default" type="submit">
            Submit Changes
          </button>
        </div>
      </form>
    </Col>
  );
}

EditRisk.propTypes = {
  accounts: PropTypes.array,
  riskTypes: PropTypes.array.isRequired,
  limits: PropTypes.array.isRequired,
  dirty: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'accounts',
  enableReinitialize: true
})(EditRisk);
