import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';
import { Hint, RadialChart } from 'react-vis';
import withGraph from 'components/withGraph';
import { formatValue, getPercent, FIELD_TYPE_SHARES } from 'utils/formatter';

class TakeMakeOtherGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataPoint: null,
      showTooltip: false
    };

    this.showTooltip = this.showTooltip.bind(this);
    this.clearTooltip = this.clearTooltip.bind(this);
  }

  showTooltip(dataPoint) {
    this.setState({
      dataPoint,
      showTooltip: true
    });
  }

  clearTooltip() {
    this.setState({
      dataPoint: null,
      showTooltip: false
    });
  }

  render() {
    const { orders, width, height } = this.props;
    const { dataPoint, showTooltip } = this.state;

    let takeTotal = 0;
    let makeTotal = 0;
    let otherTotal = 0;

    orders.forEach(row => {
      takeTotal += row.DarkTake_ExecShares + row.LitTake_ExecShares;
      makeTotal += row.DarkMake_ExecShares + row.LitMake_ExecShares;
      otherTotal += row.DarkOther_ExecShares + row.LitOther_ExecShares;
    });

    let fullTotal = takeTotal + makeTotal + otherTotal;

    const data = [
      {
        angle: takeTotal,
        groupTotal: takeTotal,
        fullTotal: fullTotal,
        label: getPercent(takeTotal, fullTotal),
        name: 'Take Total',
        color: '#38987E'
      },
      {
        angle: makeTotal,
        groupTotal: makeTotal,
        fullTotal: fullTotal,
        label: getPercent(makeTotal, fullTotal),
        name: 'Make Total',
        color: '#59B89E'
      },
      {
        angle: otherTotal,
        groupTotal: otherTotal,
        fullTotal: fullTotal,
        label: getPercent(otherTotal, fullTotal),
        name: 'Other Total',
        color: '#27F4BA'
      }
    ];

    return (
      <React.Fragment>
        {fullTotal > 0 && (
          <div>
            <RadialChart
              colorType={'literal'}
              showLabels={true}
              data={data}
              height={height}
              width={width}
              labelsRadiusMultiplier={0.9}
              labelsStyle={{ fill: '#FFFFFF' }}
              onValueMouseOver={this.showTooltip}
              onValueMouseOut={this.clearTooltip}
            >
              {showTooltip && (
                <Hint value={dataPoint}>
                  <div className="mark-series-hint">
                    <p>Name: {dataPoint.name}</p>
                    <p>
                      Shares:{' '}
                      {formatValue(dataPoint.groupTotal, FIELD_TYPE_SHARES, 3)}
                    </p>
                  </div>
                </Hint>
              )}
            </RadialChart>
            <ul className="color-legend list-unstyled color-legend-take">
              <li>Take</li>
              <li>Make</li>
              <li>Other</li>
            </ul>
          </div>
        )}
      </React.Fragment>
    );
  }
}

TakeMakeOtherGraph.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired,
  orders: PropTypes.array.isRequired
};

export default withGraph(TakeMakeOtherGraph);
