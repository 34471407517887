import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-sm-12">
            <span>&copy; 2018 Global Liquidity Partners LLC - All Rights, Titles and Interest in this site and any content contained herein are the exclusive property of Global Liquidity Partners LLC</span>
          </div>
          <div className="col-md-4 col-sm-12">
            <p>Support Phone: <a href="tel:+1-732-204-8999">+1-732-204-8999, option 1</a></p>
            <p>Support Email: <a href="mailto: tradedesk@acsexecution.com">tradedesk@acsexecution.com</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

export default Footer;

