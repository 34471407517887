import React from 'react';
import PropTypes from 'prop-types';

function GraphFilter({ color, label, onChange, shown }) {
  return (
    <div className={`legend-item color-${color}`}>
      <input type="checkbox" checked={shown} onChange={onChange} />
      <span onClick={onChange}>{label}</span>
    </div>
  );
}

GraphFilter.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired
};

export default GraphFilter;
