import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import RowRenderer from './RowRenderer';
import '../DataGrid.css';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DATA_SOURCE_EIGHT } from 'utils/constants';

class DataList extends Component {
  handleGridSort = (sortColumn, sortDirection) => {
    const { setSortDirection, setSortColumn, sortDataSource } = this.props;

    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
    sortDataSource(DATA_SOURCE_EIGHT);
  };

  rowGetter = i => {
    const { data } = this.props;

    return data && data[i];
  };

  onRowClick = (rowIdx, row) => {
    if (rowIdx === -1) {
      return;
    }

    const {
      onValueClick,
      dataKeyForOnClick,
      dateColumnKey,
      routeKeyForOnClick
    } = this.props;

    onValueClick(
      row[dataKeyForOnClick],
      row[dateColumnKey],
      row[routeKeyForOnClick]
    );
  };

  formatDataForCsv = (data, columns) => {
    let columnNames = columns.map(columnData => {
      return columnData.name;
    });

    let rawData = data.map(row => {
      return columns.map(columnData => {
        let value = row[columnData.key];

        if (value && columnData.formatter) {
          value = columnData.formatter({ value: value });
        }

        return value;
      });
    });

    rawData.unshift(columnNames);

    return rawData;
  };

  render() {
    const { data, sortDirection, columns, date } = this.props;

    let newRows = [];

    if (data) {
      newRows = data.slice(0);
    }

    return (
      <Row className="data-list">
        <Col sm={12}>
          <ReactDataGrid
            onGridSort={this.handleGridSort}
            columns={columns}
            rowGetter={this.rowGetter}
            rowsCount={newRows.length}
            minHeight={300}
            onRowClick={this.onRowClick}
            rowRenderer={RowRenderer}
            enableCellAutoFocus={false}
          />
          <div className="csv-download">
            <CSVLink
              data={this.formatDataForCsv(newRows, columns)}
              filename={`${moment(date).format(
                'YYYY-MM-DD'
              )}-order-performance-grid.csv`}
            >
              Download CSV
            </CSVLink>
          </div>
        </Col>
      </Row>
    );
  }
}

DataList.propTypes = {
  sortDirection: PropTypes.string,
  sortColumn: PropTypes.string,
  data: PropTypes.array.isRequired,
  onValueClick: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  dataKeyForOnClick: PropTypes.string.isRequired,
  dateColumnKey: PropTypes.string.isRequired,
  routeKeyForOnClick: PropTypes.string.isRequired
};

export default DataList;
