import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import rest from 'redux/modules/rest';
import dataSourceRest from 'redux/modules/dataSourceRest';
import { clearSessionStorage } from 'utils/auth';
import accountService from 'routes/Risk/reducer';
import timeFrame from 'routes/Orders/routes/Details/containers/reducer';
import dataStoreReducer from './reducers/dataStoreReducer';

const USER_LOGOUT = 'app/USER_LOGOUT';

export function logoutUser() {
  return { type: USER_LOGOUT };
}

export default function createReducer(injectedReducers) {
  const appReducer = combineReducers({
    form: formReducer,
    router: routerReducer,
    ...injectedReducers,
    ...rest.reducers,
    ...dataSourceRest.reducers,
    accountService,
    timeFrame,
    dataStore: dataStoreReducer
  });

  return (state, action) => {
    if (action.type === USER_LOGOUT) {
      clearSessionStorage();
      state = undefined;
    }

    return appReducer(state, action);
  };
}
