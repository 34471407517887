import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';
import { Hint, RadialChart } from 'react-vis';
import withGraph from 'components/withGraph';
import { getPercent } from 'utils/formatter';
import ColorGenerator from 'utils/colorGenerator';
import { formatValue } from 'utils/formatter';
import { sortBy } from 'underscore';

class LastMarketGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
      dataPoint: null
    };
  }

  clearTooltip = () => {
    this.setState({
      showTooltip: false,
      dataPoint: null
    });
  };

  showTooltip = dataPoint => {
    this.setState({
      showTooltip: true,
      dataPoint: dataPoint
    });
  };

  sortLegendValues = values => {
    return sortBy(values, function(valueData) {
      return -valueData.value;
    });
  };

  render() {
    const { height, width, data } = this.props;
    const { showTooltip, dataPoint } = this.state;

    let keys = Object.keys(data);

    let colorGenerator = new ColorGenerator();

    let values = [];

    keys.forEach(key => {
      if (key !== 'total') {
        values.push({
          value: data[key],
          angle: data[key],
          percentage: getPercent(data[key], data.total),
          name: key,
          color: colorGenerator.getNextColor()
        });
      }
    });

    return (
      <div>
        <RadialChart
          className="graph-left"
          colorType={'literal'}
          showLabels={true}
          data={values}
          labelsRadiusMultiplier={0.9}
          labelsStyle={{ fill: '#FFFFFF' }}
          onValueMouseOver={this.showTooltip}
          height={height}
          width={width}
          onValueMouseOut={this.clearTooltip}
        >
          {showTooltip && (
            <Hint value={dataPoint}>
              <div className="mark-series-hint">
                <p>Name: {dataPoint.name}</p>
                <p>Qty: {formatValue(dataPoint.value)}</p>
                <p>Portion: {dataPoint.percentage}</p>
              </div>
            </Hint>
          )}
        </RadialChart>
        <ul className="color-legend list-unstyled color-legend-last">
          {this.sortLegendValues(values).map(valueData => {
            let styleObj = {
              color: valueData.color
            };

            return (
              <li style={styleObj} key={valueData.name}>{`${valueData.name} - ${
                valueData.percentage
              } `}</li>
            );
          })}
        </ul>
      </div>
    );
  }
}

LastMarketGraph.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};

export default withGraph(LastMarketGraph);
