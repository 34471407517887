import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import RiskValueContainer from '../containers/RiskValueContainer';

function Account({ account }) {
  return (
    <Row className="account">
      <Col sm className="account-name">
        {account.name}
      </Col>
      {account.latest_risk_revision.risk_values.map((riskValue, key) => {
        return (
          <RiskValueContainer
            account={account}
            riskValue={riskValue}
            key={key}
          />
        );
      })}
    </Row>
  );
}

Account.propTypes = {
  account: PropTypes.object.isRequired
};

export default Account;
