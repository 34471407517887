import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from '../../../components/PrivateRoute';
import RequestPassResetEmail from '../routes/RequestPassResetEmail';
import ResetPassword from '../routes/ResetPassword';
import ResultMessage from '../../../components/ResultMessage';

function Resetting({ match }) {
  return (
    <Switch>
      <Route
        key="password_request_email_sent"
        path={`${match.path}/reset/success`}
        render={() => (
          <ResultMessage
            helmet="Password Reset Success"
            background="login-background"
            title="Password Reset"
            header="Success!"
            body="Your password has been reset. Use the link below to log in with your new password."
            actionLinkUrl="/login"
            actionLinkText="Login"
          />
        )}
        condition={false}
      />
      <PrivateRoute
        exact
        key="request_password_reset_email"
        path={`${match.path}/request`}
        component={RequestPassResetEmail}
        condition={false}
      />
      <PrivateRoute
        exact
        key="reset_password"
        path={`${match.path}/reset/:userToken`}
        component={ResetPassword}
        condition={false}
      />
      <Route
        key="password_request_email_sent"
        path={`${match.path}/request/email-sent`}
        render={() => (
          <ResultMessage
            helmet="Password Reset Email Request Sent"
            background="login-background"
            title="Password Reset"
            header="Email Sent!"
            body="The link to reset your password has been sent to you. You may need to check your spam folder."
            actionLinkUrl="/"
            actionLinkText="Back to login"
          />
        )}
        condition={false}
      />
      <Redirect from={`${match.path}`} to={`${match.path}/request`} />
    </Switch>
  );
}

export default Resetting;
