import React from 'react';
import PropTypes from 'prop-types';
import './Statistic.css';
import { formatValue } from 'utils/formatter';

function Statistic({
  title,
  showTotal,
  firstTotal,
  secondTotal = false,
  finalTotal = false,
  formatType,
  minFractionDigits = 0,
  fractionDigits
}) {
  let parts = [];

  parts.push(
    formatValue(firstTotal, formatType, minFractionDigits, fractionDigits)
  );

  if (secondTotal !== false) {
    parts.push(
      formatValue(secondTotal, formatType, minFractionDigits, fractionDigits)
    );
  }

  if (showTotal && finalTotal !== false) {
    parts.push(
      formatValue(finalTotal, formatType, minFractionDigits, fractionDigits)
    );
  }

  return (
    <div className="statistic">
      <h6>{title}</h6>
      <div>
        <h4>{parts.join(' / ')}</h4>
      </div>
    </div>
  );
}

Statistic.propTypes = {
  title: PropTypes.string.isRequired,
  showTotal: PropTypes.bool.isRequired,
  firstTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatType: PropTypes.string,
  minFractionDigits: PropTypes.string,
  fractionDigits: PropTypes.string
};

export default Statistic;
