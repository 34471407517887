import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { formatValue } from 'utils/formatter';

function Limit({ limit }) {
  let message = '';
  let validationType = limit.risk_type.validation_type;
  let value = formatValue(limit.value, limit.risk_type.number_type);

  if (validationType === 'max_order_validation') {
    message = `Each row cannot exceed ${value}`;
  } else if (validationType === 'broker_level_validation') {
    message = `Total column cannot exceed ${value}`;
  }

  return (
    <Col sm className="limit">
      {message}
    </Col>
  );
}

Limit.propTypes = {
  limit: PropTypes.object.isRequired
};

export default Limit;
