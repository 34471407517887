import React, { Component } from 'react';
import { Collapse, Button } from 'reactstrap';
import OrderFilters from '../OrderFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

class SideFilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = { collapse: false };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  filterSelected = params => {
    const { onFilter } = this.props;
    this.toggle();
    onFilter(params);
  };

  render() {
    const { orders } = this.props;

    return (
      <div>
        <div className="sidebar-button-container">
          <Button className="show-filters-button" onClick={this.toggle}>
            <p>
              <span>Filters</span>
              <FontAwesomeIcon icon={faSlidersH} />
            </p>
          </Button>
        </div>
        <div className="sidebar-container">
          <Collapse isOpen={this.state.collapse}>
            <div className="sidebar">
              <OrderFilters
                orders={orders}
                onFilter={this.filterSelected}
                closeSideBar={this.toggle}
              />
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

SideFilterBar.propTypes = {
  orders: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired
};

export default SideFilterBar;
