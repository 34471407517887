import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import SessionTimer from './SessionTimer';
import { getUserData } from 'utils/auth';
import rest from 'redux/modules/rest';
import { push } from 'react-router-redux';

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(push('/logout')),
  refreshToken: refreshToken => {
    const params = {
      body: JSON.stringify({ refresh_token: refreshToken })
    };

    return dispatch(rest.actions.refreshToken(null, params));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withAlert(SessionTimer));
