import PrivateRoute from '../components/PrivateRoute';
import { Route } from 'react-router-dom';
import React from 'react';
import LoginContainer from './Login';
import LogoutContainer from './Logout';
import IndexContainer from './Index/index';
import Orders from './Orders';
import Risk from './Risk';
import Resetting from './Resetting';
import Profile from './Profile';

export default [
  <PrivateRoute
    condition={false}
    key="login"
    path="/login"
    component={LoginContainer}
  />,
  <PrivateRoute key="logout" path="/logout" component={LogoutContainer} />,
  <Route key="logout" path="/logout" component={LoginContainer} />,
  <Route key="resetting" path="/resetting" component={Resetting} />,
  <PrivateRoute key="profile" path="/profile" component={Profile} />,
  <PrivateRoute key="risk" path="/risk" component={Risk} />,
  <PrivateRoute key="orders" path="/orders" component={Orders} />,
  <Route key="index" path="/" component={IndexContainer} />
];
