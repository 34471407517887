import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PageTitle from 'components/PageTitle';
import moment from 'moment';
import './Order.css';
import _ from 'underscore';
import { createSocket } from 'reducers/socketIO';
import OverviewGraphs from './OverviewGraphs';
import DateSelector from 'components/DateSelector';
import { Link } from 'react-router-dom';

class OrdersOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      today: false,
      date: moment(),
      data8: {},
      data9: {},
      filters: {},
      selectDateOpen: false,
      firstDate: null
    };

    this.handleFilter = this.handleFilter.bind(this);
    this.handleOrderClick = this.handleOrderClick.bind(this);
  }

  componentDidMount() {
    this.loadOrders();
  }

  loadOrders() {
    const {
      match: {
        params: { date }
      }
    } = this.props;

    let momentData = date === 'today' ? moment() : moment(date);
    let today = momentData.isSame(moment(), 'day');

    this.setState(
      {
        today: today,
        date: momentData,
        dateFormatted: momentData.format('YYYY-MM-DD')
      },
      this.loadDataSources
    );
  }

  componentDidUpdate(prevProps) {
    const newDate = this.props.match.params.date;
    const oldDate = prevProps.match.params.date;
    const newClientId = this.props.selectedClientId;
    const oldClientId = prevProps.selectedClientId;

    if (newDate !== oldDate || newClientId !== oldClientId) {
      this.loadOrders();
    }
  }

  loadDataSources() {
    this.disconnect();

    const {
      dataSource8,
      dataSource9,
      loadDataSource8,
      loadDataSource9,
      initDatasourceRecords,
      selectedClientId: client_id
    } = this.props;

    const { today, dateFormatted } = this.state;

    if (!dataSource8.sync && !dataSource8.loading) {
      loadDataSource8(dateFormatted, client_id);
    }

    if (!dataSource9.sync && !dataSource9.loading) {
      loadDataSource9(dateFormatted, client_id);
    }

    if (today) {
      const socket = (this.socket = createSocket('overviewPage'));

      socket.on('authenticated', () => {
        socket.emit('join-room', {
          clientId: client_id,
          date: dateFormatted
        });

        socket.on('dataSource8', results => {
          initDatasourceRecords(
            dateFormatted,
            client_id,
            results,
            'dataSource8'
          );
        });

        socket.on('dataSource9', results => {
          initDatasourceRecords(
            dateFormatted,
            client_id,
            results,
            'dataSource9'
          );
        });
      });

      socket.on('disconnect', function() {
        socket.off('dataSource8');
        socket.off('dataSource9');
      });
    }
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    const { dataSource8, dataSource9 } = nextProps;
    const { filters } = previousState;

    let newState = Object.assign({}, previousState);

    newState = applyFilters(dataSource8, newState, 'data8', filters);

    newState = Object.assign({}, newState, {
      data9: dataSource9
    });

    return newState;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  componentWillUnmount() {
    this.disconnect();
  }

  handleFilter(data) {
    this.setState({
      filters: data
    });
  }

  handleOrderClick(uniqueOrderId, date, detailPage) {
    const { push } = this.props;

    push(`/orders/${date}/${uniqueOrderId}/details/${detailPage}`);
  }

  toggleDateSelector = () => {
    this.setState({
      selectDateOpen: !this.state.selectDateOpen
    });
  };

  onDateSelect = date => {
    date = moment(date).format('YYYY-MM-DD');

    if (this.state.dateFormatted === date) {
      this.toggleDateSelector();
      return;
    }

    const { push } = this.props;

    this.toggleDateSelector();
    push(`/orders/${date}`);
  };

  render() {
    const { today, date, data8, data9 } = this.state;
    const { push, dataSource8: allDataSource8 } = this.props;

    return (
      <div className="orders">
        <Helmet>
          <title>Orders</title>
        </Helmet>
        <PageTitle
          title={`${moment(date).format('dddd, MMMM Do YYYY')}${
            today ? ' (Today)' : ''
          }`}
        >
          <DateSelector date={date} onDateSelect={this.onDateSelect}>
            <Link to="/orders/today" onClick={this.toggleDateSelector}>
              Go To Today
            </Link>
          </DateSelector>
        </PageTitle>
        <OverviewGraphs
          push={push}
          allDataSource8={allDataSource8.data}
          data9={data9.data}
          handleOrderClick={this.handleOrderClick}
          handleFilter={this.handleFilter}
          data8={data8.data}
          date={date}
        />
      </div>
    );
  }
}

const applyFilters = (dataSource, newState, name, filters) => {
  if (dataSource && dataSource.data) {
    let filterCount = 0;
    const filterTypes = Object.keys(filters);

    let filtered = _.filter(dataSource.data, row => {
      let matches = false;

      for (let i = 0; i < filterTypes.length; i++) {
        let filterType = filterTypes[i];
        let filterValue = filters[filterType];

        if (filterValue.length === 0) {
          continue;
        } else {
          filterCount++;
        }

        if (filterType === 'Algorithm') {
          filterType = 'StrategyName';
        }

        // If the filter value is a one-length array, compare directly
        // Otherwise, check that the object's value matches any value in the array
        const matchesFilter =
          filterValue.length === 1
            ? row[filterType] === filterValue[0]
            : filterValue.includes(row[filterType]);

        matches = matchesFilter;

        // since all filters need to match, if this one doesn't, we can discontinue
        // filtering for this object
        if (!matchesFilter) {
          break;
        }
      }

      return matches;
    });

    if (filterCount === 0) {
      filtered = dataSource.data;
    } else {
      filtered = _.uniq(filtered, r => r.UniqueOrderId);
    }

    newState = Object.assign({}, newState, {
      [name]: {
        ...dataSource,
        data: filtered
      }
    });
  }
  return newState;
};

OrdersOverview.propTypes = {};

export default OrdersOverview;
