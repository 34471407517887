import Custom from '../components/Custom.js';
import { connect } from 'react-redux';
import dataSourceRest from 'redux/modules/dataSourceRest';
import {
  resetTimeFrame,
  resetHighLow,
  storeDefaultTimeFrame,
  storeDefaultHighLow,
  storeTimeFrame,
  storeHighLow,
  resetAll
} from './reducer';
import {
  resetDataStore,
  appendDatasourceRecords,
  initDatasourceRecords
} from 'reducers/dataStoreReducer';

const mapStateToProps = state => {
  return {
    dataSource1: state.dataStore.dataSource1,
    dataSource3: state.dataStore.dataSource3,
    dataSource4: state.dataStore.dataSource4,
    dataSource5: state.dataStore.dataSource5,
    dataSource7: state.dataStore.dataSource7,
    dataSource8: state.dataStore.dataSource8,
    loaded:
      state.dataStore.dataSource1.loaded &&
      state.dataStore.dataSource3.loaded &&
      state.dataStore.dataSource4.loaded &&
      state.dataStore.dataSource5.loaded &&
      state.dataStore.dataSource7.loaded,
    timeFrame: state.timeFrame.timeFrame,
    defaultTimeFrameSet: state.timeFrame.defaultTimeFrameSet,
    yAxis: [state.timeFrame.low, state.timeFrame.high]
  };
};

const mapDispatchToProps = dispatch => ({
  loadDataSource: (date, orderId) => {
    return Promise.all([
      dispatch(dataSourceRest.actions.dataSource1({ date, orderId })),
      dispatch(dataSourceRest.actions.dataSource3({ date, orderId })),
      dispatch(dataSourceRest.actions.dataSource4({ date, orderId })),
      dispatch(dataSourceRest.actions.dataSource5({ date, orderId })),
      dispatch(dataSourceRest.actions.dataSource7({ date, orderId }))
    ]);
  },
  loadDataSource8: (date, clientId) =>
    dispatch(dataSourceRest.actions.dataSource8({ date, clientId })),
  resetTimeFrame: () => dispatch(resetTimeFrame()),
  storeDefaultTimeFrame: (min, max) =>
    dispatch(storeDefaultTimeFrame(min, max)),
  storeDefaultHighLow: (high, low) => dispatch(storeDefaultHighLow(high, low)),
  storeTimeFrame: (min, max) => dispatch(storeTimeFrame(min, max)),
  storeHighLow: (high, low) => dispatch(storeHighLow(high, low)),
  resetDataStore: () => dispatch(resetDataStore()),
  resetAll: () => dispatch(resetAll()),
  appendDatasourceRecords: (results, dataSource) =>
    dispatch(appendDatasourceRecords(results, dataSource)),
  initDatasourceRecords: (date, clientId, results, dataSource) =>
    dispatch(initDatasourceRecords(date, clientId, results, dataSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Custom);
