import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';
import {
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  MarkSeries,
  Hint
} from 'react-vis';
import withGraph from 'components/withGraph';
import CustomAxisLabel from 'components/CustomAxisLabel';
import {
  formatValue,
  FIELD_TYPE_DOLLAR,
  FIELD_TYPE_PERCENT
} from 'utils/formatter';
import { LARGE_GRAPH_HEIGHT } from 'components/withGraph';
import RadioFilter from '../RadioFilter';

const AvgPriceToRefBibs = {
  key: 'AvgPriceToRefBibs',
  label: 'Custom VWAP (bips)'
};
const AvgPriceToVwapRefBibs = {
  key: 'AvgPriceToVwapRefBibs',
  label: 'Arrival Px (bips)'
};

class SummaryGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
      dataPoint: null,
      averageOrArrival: AvgPriceToRefBibs.key,
      gridSize: {
        minX: null,
        maxX: null,
        minY: null,
        maxY: null
      },
      graphData: []
    };

    this.clearTooltip = this.clearTooltip.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
  }

  clearTooltip() {
    this.setState({
      showTooltip: false,
      dataPoint: null
    });
  }

  showTooltip(dataPoint) {
    this.setState({
      showTooltip: true,
      dataPoint: dataPoint
    });
  }

  onFilterChanged = () => {
    const { averageOrArrival } = this.state;

    if (averageOrArrival === AvgPriceToRefBibs.key) {
      this.setState({ averageOrArrival: AvgPriceToVwapRefBibs.key });
    } else {
      this.setState({ averageOrArrival: AvgPriceToRefBibs.key });
    }
  };

  render() {
    const { orders, width, onValueClick } = this.props;
    const { showTooltip, dataPoint, gridSize, averageOrArrival } = this.state;
    gridSize.minX = null;
    gridSize.maxX = null;

    const graphData = orders.map(row => {
      let x = row[averageOrArrival];
      let y = row.SpreadToRefBibs;

      if (gridSize.minX === null || x < gridSize.minX) {
        gridSize.minX = x;
      }

      if (gridSize.maxX === null || x > gridSize.maxX) {
        gridSize.maxX = x;
      }

      if (gridSize.minY === null || y < gridSize.minY) {
        gridSize.minY = y;
      }

      if (gridSize.maxY === null || y > gridSize.maxY) {
        gridSize.maxY = y;
      }

      return {
        x: x,
        y: y,
        size: row.OrderQty,
        payload: row
      };
    });

    const paddingX = 1;
    const paddingY = 40;

    return (
      <div>
        <XYPlot
          height={LARGE_GRAPH_HEIGHT}
          width={width}
          margin={{ left: 85, right: 20, top: 10, bottom: 75 }}
          xDomain={[gridSize.minX - paddingX, gridSize.maxX + paddingX]}
          yDomain={[gridSize.minY - paddingY, gridSize.maxY + paddingY]}
        >
          <VerticalGridLines />
          <VerticalGridLines style={{ stroke: 'black' }} tickValues={['0']} />

          <HorizontalGridLines />

          <XAxis />
          <XAxis
            tickValues={['0']}
            style={{
              ticks: { stroke: 'black', fill: 'black' },
              text: { stroke: 'black' }
            }}
          />

          <YAxis />
          <CustomAxisLabel title="Performance in Bips" xAxis={true} />
          <CustomAxisLabel title="Spread in Bips" marginTop={0} />

          <MarkSeries
            className="mark-series-example"
            strokeWidth={2}
            opacity="0.8"
            sizeRange={[5, 15]}
            data={graphData}
            onValueClick={event => {
              if (event && event.payload && event.payload.UniqueOrderId) {
                onValueClick(
                  event.payload.UniqueOrderId,
                  event.payload.SentDate,
                  event.payload.DetailPage
                );
              }
            }}
            onValueMouseOver={this.showTooltip}
            onValueMouseOut={this.clearTooltip}
          />

          {showTooltip && (
            <Hint value={dataPoint}>
              <div className="mark-series-hint">
                <p>Symbol: {dataPoint.payload.Symbol}</p>
                <p>Side: {dataPoint.payload.Side}</p>
                <p>Qty: {formatValue(dataPoint.payload.OrderQty)}</p>
                <p>Strategy: {dataPoint.payload.StrategyName}</p>
                <p>
                  {dataPoint.payload.PercentComplete
                    ? formatValue(
                        dataPoint.payload.PercentComplete * 100,
                        FIELD_TYPE_PERCENT,
                        1,
                        1
                      )
                    : '0.0 %'}{' '}
                  Complete
                </p>
                <p>
                  Price:{' '}
                  {formatValue(
                    dataPoint.payload.AvgPrice,
                    FIELD_TYPE_DOLLAR,
                    4
                  )}
                </p>
              </div>
            </Hint>
          )}
        </XYPlot>
        <div className="filter-container">
          <div className="items">
            <RadioFilter
              onChangeFunction={this.onFilterChanged}
              isSelected={averageOrArrival === AvgPriceToVwapRefBibs.key}
              value={AvgPriceToVwapRefBibs}
            />
            <RadioFilter
              onChangeFunction={this.onFilterChanged}
              isSelected={averageOrArrival === AvgPriceToRefBibs.key}
              value={AvgPriceToRefBibs}
            />
          </div>
        </div>
      </div>
    );
  }
}

SummaryGraph.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired,
  orders: PropTypes.array.isRequired,
  onValueClick: PropTypes.func.isRequired
};

export default withGraph(SummaryGraph);
