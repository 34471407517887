import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import OrdersOverview from '../components/OrdersOverview';
import rest from 'redux/modules/dataSourceRest';
import { initDatasourceRecords } from 'reducers/dataStoreReducer';
import { hasRole, getUserData, ROLE_GLP_ADMIN } from 'utils/auth';

const mapStateToProps = state => {
  const user = getUserData();
  const isGlpAdmin = hasRole(user, ROLE_GLP_ADMIN);
  const { client_id } = user;

  return {
    dataSource8: state.dataStore.dataSource8,
    dataSource9: state.dataStore.dataSource9,
    isGlpAdmin: isGlpAdmin,
    selectedClientId:
      (isGlpAdmin && state.dataStore.selectedClientId) || client_id
  };
};

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  loadDataSource8: (date, clientId) =>
    dispatch(rest.actions.dataSource8({ date, clientId })),
  loadDataSource9: (date, clientId) =>
    dispatch(rest.actions.dataSource9({ date, clientId })),
  initDatasourceRecords: (date, clientId, records, dataSource) =>
    dispatch(initDatasourceRecords(date, clientId, records, dataSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersOverview);
