import React, { Component } from 'react';
import EditRiskForm from './EditRiskForm';
import { request } from 'utils/fetch';

class EditRisk extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {
      addAccount,
      addAccountRiskValue,
      addRiskTypes,
      alert,
      addBrokerLimits,
      accountsLoaded,
      riskTypesLoaded,
      brokerLimitsLoaded
    } = this.props;

    if (!accountsLoaded) {
      request('/api/accounts')
        .then(accounts => {
          accounts.forEach(account => {
            addAccount(account);

            account.latest_risk_revision.risk_values.forEach(riskValue => {
              addAccountRiskValue(account, riskValue);
            });
          });
        })
        .catch(() => {
          alert.error(
            'Woops! Something went wrong while fetching your accounts!',
            {
              timeout: 0
            }
          );
        });
    }

    if (!riskTypesLoaded) {
      request('/api/risk_types')
        .then(addRiskTypes)
        .catch(() => {
          alert.error(
            'Woops! Something went wrong while fetching your Risk Types!',
            {
              timeout: 0
            }
          );
        });
    }

    if (!brokerLimitsLoaded) {
      request('/api/limits')
        .then(addBrokerLimits)
        .catch(() => {
          alert.error(
            'Woops! Something went wrong while fetching your Limits!',
            {
              timeout: 0
            }
          );
        });
    }
  }

  onSubmit(values) {
    const {
      updateRiskValues,
      history: { push },
      dirty,
      alert
    } = this.props;

    if (!dirty) {
      alert.show('Please change values before submitting!');
      return;
    }

    updateRiskValues(values);

    push('/risk/confirm');
  }

  onCancel() {
    const { resetForm } = this.props;

    resetForm();
  }

  render() {
    const { accounts, riskTypes, limits, dirty, initialValues } = this.props;

    return (
      <EditRiskForm
        accounts={accounts}
        riskTypes={riskTypes}
        onSubmit={this.onSubmit}
        limits={limits}
        onCancel={this.onCancel}
        dirty={dirty}
        initialValues={initialValues}
      />
    );
  }
}

export default EditRisk;
