import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

class Document extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="document_item">
        <FontAwesomeIcon className="file-alt" icon={faFileAlt} />

        <a target="_blank" href={this.props.url}>
          {this.props.name}
        </a>
      </div>
    );
  }
}

Document.propTypes = {
  name: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired
};

export default Document;
