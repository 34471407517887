import React from 'react';
import Auth from 'components/Auth';
import { Alert, Form, Button } from 'reactstrap';
import { Field } from 'redux-form';
import { required, password, passwordsMatch } from 'utils/validators';
import Input from 'components/Input';

function ChangePassword({ handleSubmit, confirmCurrentPassword, error }) {
  return (
    <Auth title="Change Password">
      <div className="password-interior-container password-form-container">
        {error && <Alert color="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          {confirmCurrentPassword && (
            <Field
              id="change_password_current"
              name="currentPassword"
              type="password"
              component={Input}
              placeholder="Current Password"
              label=""
              validate={[required]}
            />
          )}
          <Field
            id="change_password"
            name="password"
            type="password"
            component={Input}
            placeholder="Password"
            label=""
            validate={[required, password, passwordsMatch]}
          />
          <Field
            id="change_password_confirm"
            name="confirmPassword"
            type="password"
            component={Input}
            placeholder="Confirm Password"
            label=""
            validate={[required, password, passwordsMatch]}
          />
          <Button color="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </Auth>
  );
}

export default ChangePassword;
