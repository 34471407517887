import Risk from './components/Risk';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import withUserProfile from 'containers/withUserProfile';
import { hasRole, ROLE_RISK_USER } from 'utils/auth';

const mapStateToProps = state => ({
  hasRiskAccess: hasRole(state.user.data, ROLE_RISK_USER)
});

const mapDispatchToProps = dispatch => ({});

const enhance = compose(
  withUserProfile,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(Risk);
