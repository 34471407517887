export const APP_STORE_TIME_FRAME = 'APP/STORE_TIME_FRAME';
export const APP_STORE_DEFAULT_HIGH_LOW = 'APP/STORE_DEFAULT_HIGH_LOW';
export const APP_STORE_DEFAULT_TIME_FRAME = 'APP/STORE_DEFAULT_TIME_FRAME';
export const APP_RESET_TIME_FRAME = 'APP/RESET_TIME_FRAME';
export const APP_RESET_ALL = 'APP/RESET_ALL';
export const APP_STORE_HIGH_LOW = 'APP/STORE_HIGH_LOW';

export const storeTimeFrame = (min, max) => ({
  type: APP_STORE_TIME_FRAME,
  payload: {
    min,
    max
  }
});

export const storeDefaultTimeFrame = (min, max) => ({
  type: APP_STORE_DEFAULT_TIME_FRAME,
  payload: {
    min,
    max
  }
});

export const resetTimeFrame = () => ({
  type: APP_RESET_TIME_FRAME
});

export const resetAll = () => ({
  type: APP_RESET_ALL
});

export const storeDefaultHighLow = (high, low) => ({
  type: APP_STORE_DEFAULT_HIGH_LOW,
  payload: {
    high,
    low
  }
});

export const storeHighLow = (high, low) => ({
  type: APP_STORE_HIGH_LOW,
  payload: {
    high,
    low
  }
});

const initialState = {
  timeFrame: {
    min: null,
    max: null
  },
  defaultTimeFrame: {
    min: null,
    max: null
  },
  high: null,
  low: null,
  defaultHigh: null,
  defaultLow: null,
  defaultTimeFrameSet: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_STORE_TIME_FRAME:
      return {
        ...state,
        timeFrame: action.payload
      };

    case APP_STORE_DEFAULT_TIME_FRAME:
      return {
        ...state,
        defaultTimeFrame: action.payload,
        timeFrame: action.payload,
        defaultTimeFrameSet: true
      };

    case APP_RESET_TIME_FRAME:
      return {
        ...state,
        timeFrame: state.defaultTimeFrame,
        high: state.defaultHigh,
        low: state.defaultLow
      };

    case APP_STORE_DEFAULT_HIGH_LOW:
      return {
        ...state,
        defaultHigh: action.payload.high,
        defaultLow: action.payload.low
      };

    case APP_STORE_HIGH_LOW:
      return {
        ...state,
        high: action.payload.high,
        low: action.payload.low
      };

    case APP_RESET_ALL:
      return Object.assign({}, initialState);

    default:
      return state;
  }
};

export default reducer;
