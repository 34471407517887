import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import OrderAnalysis from '../components/OrderAnalysis';

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path))
});

export default connect(
  null,
  mapDispatchToProps
)(OrderAnalysis);
