import React from 'react';
import {
  Borders,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from 'react-vis/es';
import CustomAxisLabel from 'components/CustomAxisLabel';
import { getXAxisTimeTick } from 'utils/formatter';
import { LARGE_GRAPH_HEIGHT } from 'components/withGraph';

function VolumeCurveGraph1({
  width,
  height,
  yAxis,
  timeFrame,
  nominalTarget,
  lowTarget,
  highTarget
}) {
  return (
    <XYPlot
      height={LARGE_GRAPH_HEIGHT}
      width={width}
      margin={{ left: 75, right: 20, top: 10, bottom: 75 }}
      yDomain={yAxis}
      xDomain={[timeFrame.min, timeFrame.max]}
      yBaseValue={yAxis[0]}
      className="graph"
    >
      <VerticalGridLines />
      <HorizontalGridLines />
      {nominalTarget.shown && (
        <LineSeries data={nominalTarget.data} color={nominalTarget.color} />
      )}
      {lowTarget.shown && (
        <LineSeries data={lowTarget.data} color={lowTarget.color} />
      )}
      {highTarget.shown && (
        <LineSeries data={highTarget.data} color={highTarget.color} />
      )}
      <Borders
        style={{
          bottom: { fill: '#fff' },
          left: { fill: '#fff' },
          right: { fill: '#fff' },
          top: { fill: '#fff' }
        }}
      />
      <XAxis tickFormat={getXAxisTimeTick} tickTotal={12} />
      <YAxis />
      <CustomAxisLabel title="Time (Eastern)" xAxis={true} />
      <CustomAxisLabel title="Curve Data" marginTop={0} />

      {/*Needed to prevent graph from hiding when all graphs are disabled.*/}
      <LineSeries data={[{ x: 0, y: 0 }, { x: 0, y: 0 }]} />
    </XYPlot>
  );
}

export default VolumeCurveGraph1;
