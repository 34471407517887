import { reduxForm, SubmissionError } from 'redux-form';
import ChangePasswordWrapper from '../components';
import { FORM_CHANGE_PASSWORD } from 'utils/constants';
import rest from 'redux/modules/rest';
import { getUserData } from 'utils/auth';
import { push } from 'react-router-redux';

function onSubmit(values, dispatch) {
  const params = { body: JSON.stringify(values) };

  return new Promise((resolve, reject) => {
    const { user_id } = getUserData();
    dispatch(
      rest.actions.changePassword({ id: user_id }, params, err => {
        if (err) {
          reject(new SubmissionError({ _error: err.body.message }));
          return;
        }

        dispatch(push('/profile/change-password/success'));
      })
    );
  });
}

export default reduxForm({
  form: FORM_CHANGE_PASSWORD,
  onSubmit
})(ChangePasswordWrapper);
