import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PageTitle from 'components/PageTitle';
import Document from './Document';
import { Col, Row } from 'reactstrap';
import './OrderAnalysis.css';

class OrderAnalysis extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const items = [
      { name: 'Test Document 1', url: '#' },
      { name: 'Test Document 2', url: '#' },
      { name: 'Test Document 3', url: '#' },
      { name: 'Test Document 4', url: '#' },
      { name: 'Test Document 5', url: '#' },
      { name: 'Test Document 6', url: '#' },
      { name: 'Test Document 7', url: '#' },
      { name: 'Test Document 8', url: '#' },
      { name: 'Test Document 9', url: '#' },
      { name: 'Test Document 10', url: '#' }
    ];

    let documents = items.map(item => {
      return <Document name={item.name} url={item.url} />;
    });

    return (
      <div className="order_analysis">
        <Helmet>
          <title>Order Analysis</title>
        </Helmet>
        <PageTitle title={'Order Analysis'} />

        <Row className="order-list">
          <Col md={{ size: 10, offset: 1 }}>{documents}</Col>
        </Row>
      </div>
    );
  }
}

OrderAnalysis.propTypes = {};

export default OrderAnalysis;
