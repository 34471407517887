import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import { Col } from 'reactstrap';
import { Field } from 'redux-form';
import { FIELD_TYPE_DOLLAR, FIELD_TYPE_PERCENT } from 'utils/formatter';

const normalize = value => value && parseFloat(value.replace(/[, $%]/g, ''));

const calcNewValue = (fieldValue, step) => {
  let value = normalize(fieldValue.toString());
  return (value += step);
};

function RiskValue({ account, riskValue, changeField, fieldName, fieldValue }) {
  let prefix = '';
  let suffix = '';

  switch (riskValue.risk_type.number_type) {
    case FIELD_TYPE_PERCENT:
      suffix = ' %';
      break;

    case FIELD_TYPE_DOLLAR:
      prefix = '$ ';
      break;

    default:
      suffix = '';
  }

  return (
    <Col sm className="risk-value">
      <Field
        name={fieldName}
        prefix={prefix}
        suffix={suffix}
        type="number"
        component={Input}
        label={false}
        normalize={normalize}
        onIncrement={() => {
          changeField(
            fieldName,
            calcNewValue(fieldValue, riskValue.risk_type.step)
          );
        }}
        onDecrement={() => {
          changeField(
            fieldName,
            calcNewValue(fieldValue, -riskValue.risk_type.step)
          );
        }}
      />
    </Col>
  );
}

RiskValue.propTypes = {
  account: PropTypes.object.isRequired,
  riskValue: PropTypes.object.isRequired
};

export default RiskValue;
