import React from 'react';
import PropTypes from 'prop-types';
import './CustomAxisLabels.css';

function CustomAxisLabel({
  marginTop,
  marginLeft,
  innerHeight,
  innerWidth,
  title,
  xAxis
}) {
  const yLabelOffset = {
    y: marginTop + innerHeight / 2 + title.length * 2,
    x: 15
  };

  const xLabelOffset = {
    x: marginLeft + innerWidth / 2 - title.length * 2,
    y: 1.2 * innerHeight
  };

  const transform = xAxis
    ? `translate(${xLabelOffset.x}, ${xLabelOffset.y})`
    : `translate(${yLabelOffset.x}, ${yLabelOffset.y}) rotate(-90)`;

  return (
    <g transform={transform} className="custom-axis-label">
      <text className="unselectable axis-labels">{title}</text>
    </g>
  );
}

CustomAxisLabel.displayName = 'CustomAxisLabel';
CustomAxisLabel.requiresSVG = true;

CustomAxisLabel.propTypes = {
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
  innerHeight: PropTypes.number,
  innerWidth: PropTypes.number,
  title: PropTypes.string.isRequired,
  xAxis: PropTypes.bool
};

export default CustomAxisLabel;
