import React from 'react';
import PropTypes from 'prop-types';
import SummaryGraph from './Graphs/SummaryGraph';
import { Row, Col } from 'reactstrap';
import TotalData from './TotalData';
import DarkLitGraph from './Graphs/DarkLitGraph';
import TakeMakeOtherGraph from './Graphs/TakeMakeOtherGraph';
import LastMarketGraph from './Graphs/LastMarketGraph';
import DataListContainer from 'components/DataGrid/containers/DataListContainer';
import { formatValue, FIELD_TYPE_DOLLAR } from 'utils/formatter';
import SideFilterBar from './SideFilterBar/SideFilterBar';
import { FIELD_TYPE_PERCENT } from 'utils/formatter';

const columns = [
  {
    key: 'Trader',
    name: 'Trader',
    sortable: true,
    width: 200
  },
  {
    key: 'Side',
    name: 'Side',
    sortable: true,
    width: 150
  },
  {
    key: 'Symbol',
    name: 'Symbol',
    sortable: true,
    width: 150
  },
  {
    key: 'OrderQty',
    name: 'Order Qty',
    sortable: true,
    width: 150,
    formatter: ({ value }) => {
      return formatValue(value);
    }
  },
  {
    key: 'ExecutedShares',
    name: 'Exec Qty',
    sortable: true,
    width: 150,
    formatter: ({ value }) => {
      return formatValue(value);
    }
  },
  {
    key: 'StrategyName',
    name: 'Strategy',
    sortable: true,
    width: 150
  },
  {
    key: 'PercentComplete',
    name: 'Percent Complete',
    sortable: true,
    width: 175,
    formatter: ({ value }) => {
      return formatValue(value * 100, FIELD_TYPE_PERCENT, 1, 1);
    }
  },
  {
    key: 'LimitPrice',
    name: 'Price',
    sortable: true,
    formatter: ({ value }) => {
      if (!value || value === 0) {
        return 'MKT';
      }
      return formatValue(value, FIELD_TYPE_DOLLAR, 2);
    },
    width: 150
  },
  {
    key: 'AvgPrice',
    name: 'Avg. Price',
    sortable: true,
    width: 150,
    formatter: ({ value }) => {
      return formatValue(value, FIELD_TYPE_DOLLAR, 4, 4);
    }
  },
  {
    key: 'ReferencePrice',
    name: 'Reference Price',
    sortable: true,
    width: 150,
    formatter: ({ value }) => {
      return formatValue(value, FIELD_TYPE_DOLLAR, 4);
    }
  },
  {
    key: 'Status',
    name: 'Status',
    sortable: true,
    width: 150
  },
  {
    key: 'AvgPriceToVwapRefBibs',
    name: 'VWAP Bips',
    sortable: true,
    width: 150,
    formatter: ({ value }) => {
      return formatValue(value, '', 2, 2);
    }
  },
  {
    key: 'AvgPriceToRefBibs',
    name: 'Arrival Price Bips',
    sortable: true,
    width: 150,
    formatter: ({ value }) => {
      return formatValue(value, '', 2, 2);
    }
  }
];

function OverviewGraphs({
  allDataSource8,
  push,
  data8,
  data9,
  handleOrderClick,
  handleFilter,
  date
}) {
  return (
    <React.Fragment>
      <SideFilterBar orders={allDataSource8} onFilter={handleFilter} />
      <TotalData orders={data8} />
      <Row className="order-performance">
        <Col sm={12}>
          <SummaryGraph
            orders={data8}
            size={1}
            title="Order Performance"
            hPadding={50}
            onValueClick={handleOrderClick}
          />
        </Col>
      </Row>
      <Row className="order-chart">
        <Col lg={4} sm={12} className="d-flex chart-item">
          <DarkLitGraph orders={data8} size={0.33} title="Dark/Lit" />
        </Col>
        <Col lg={4} md={12} className="d-flex chart-item">
          <TakeMakeOtherGraph
            orders={data8}
            size={0.33}
            title="Take/Make/Other"
          />
        </Col>
        <Col lg={4} md={12} className="d-flex chart-item">
          <LastMarketGraph data={data9} size={0.33} title="Last Market" />
        </Col>
      </Row>
      <DataListContainer
        onValueClick={handleOrderClick}
        dataKeyForOnClick="UniqueOrderId"
        dateColumnKey="SentDate"
        routeKeyForOnClick="DetailPage"
        data={data8}
        columns={columns}
        date={date}
      />
    </React.Fragment>
  );
}

OverviewGraphs.propTypes = {
  data8: PropTypes.array.isRequired,
  data9: PropTypes.object.isRequired,
  allDataSource8: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  handleOrderClick: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired
};

export default OverviewGraphs;
