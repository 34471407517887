import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import OrdersOverview from '../routes/Overview';
import OrderAnalysis from '../routes/Analysis';
import Details from '../routes/Details';
import { Custom } from '../routes/Details';
import NRoute from '../routes/NRoute';
import { getUserData, hasRole } from 'utils/auth';

function Orders({ match }) {
  const userData = getUserData();
  const isGlpAdmin = hasRole(userData, 'ROLE_GLP_ADMIN');
  const isCODA = userData.client_id === 'CODA';

  return (
    <Switch>
      {(isGlpAdmin || isCODA) && (
        <Route
          exact
          key="order_nroute"
          path={`${match.path}/nroute/:date?/:order_id?`}
          component={NRoute}
        />
      )}
      <Route
        exact
        key="order_analysis"
        path={`${match.path}/analysis`}
        component={OrderAnalysis}
      />
      <Route
        exact
        key="order_overview"
        path={`${match.path}/:date`}
        component={OrdersOverview}
      />
      <Route
        exact
        key="order_detail"
        path={`${match.path}/:date/:order_id/details/Custom`}
        component={Custom}
      />
      <Route
        exact
        key="order_detail"
        path={`${match.path}/:date/:order_id/details/Interval`}
        component={Details}
      />
      <Redirect from={`${match.path}`} to={`${match.path}/today`} />
    </Switch>
  );
}

export default Orders;
