import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';
import {
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  MarkSeries,
  LineSeries
} from 'react-vis';
import CandlestickSeries from 'components/CandleStick';
import { LARGE_GRAPH_HEIGHT } from 'components/withGraph';

class PricesGraph1 extends Component {
  render() {
    const {
      width,
      dataSource4,
      timeFrame,
      dataSource5_marketPrice,
      dataSource1_executionPrice,
      yAxis,
      colors,
      enableExecutionPrice,
      enableReferencePrice,
      enableMarketPrice
    } = this.props;

    return (
      <XYPlot
        height={LARGE_GRAPH_HEIGHT}
        width={width}
        margin={{ left: 75, right: 20, top: 10, bottom: 75 }}
        yDomain={yAxis}
        xDomain={[timeFrame.min, timeFrame.max]}
        yBaseValue={yAxis[0]}
        className="graph"
      >
        <VerticalGridLines />
        <HorizontalGridLines />

        {/* Market Price vs Activity Time */}
        {enableMarketPrice && (
          <CandlestickSeries
            colors={[colors[5], colors[6]]}
            data={dataSource5_marketPrice}
          />
        )}

        {/* Execution Price vs Activity Time */}
        {enableExecutionPrice && (
          <MarkSeries color={colors[4]} data={dataSource1_executionPrice} />
        )}

        {/* Reference Price */}
        {enableReferencePrice && (
          <LineSeries
            color={colors[2]}
            data={[
              { x: timeFrame.min, y: dataSource4[0].ReferencePrice },
              { x: timeFrame.max, y: dataSource4[0].ReferencePrice }
            ]}
          />
        )}

        {/*Needed to prevent graph from hiding when all graphs are disabled.*/}
        <LineSeries data={[{ x: 0, y: 0 }, { x: 0, y: 0 }]} />
      </XYPlot>
    );
  }
}

PricesGraph1.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired,
  dataSource1_executionPrice: PropTypes.array.isRequired,
  dataSource4: PropTypes.array.isRequired,
  dataSource5_marketPrice: PropTypes.array.isRequired,
  enableExecutionPrice: PropTypes.bool.isRequired,
  enableReferencePrice: PropTypes.bool.isRequired,
  enableMarketPrice: PropTypes.bool.isRequired
};

export default PricesGraph1;
