import { connect } from 'react-redux';
import {
  addAccountRiskValue,
  addAccount,
  updateRiskValues,
  addRiskTypes,
  addBrokerLimits
} from '../../../reducer';
import { withAlert } from 'react-alert';
import { isDirty, reset } from 'redux-form';
import EditRisk from '../components/EditRisk';

const mapStateToProps = state => ({
  dirty: isDirty('accounts')(state),
  accounts: state.accountService.accounts,
  riskTypes: state.accountService.riskTypes,
  limits: state.accountService.brokerLimits,
  accountsLoaded: state.accountService.accountsLoaded,
  riskTypesLoaded: state.accountService.riskTypesLoaded,
  brokerLimitsLoaded: state.accountService.brokerLimitsLoaded,
  initialValues: state.accountService.initialFormValues
});

const mapDispatchToProps = dispatch => ({
  addAccount: account => dispatch(addAccount(account)),
  addAccountRiskValue: (account, riskValue) =>
    dispatch(addAccountRiskValue(account, riskValue)),
  updateRiskValues: values => dispatch(updateRiskValues(values)),
  addRiskTypes: values => dispatch(addRiskTypes(values)),
  addBrokerLimits: values => dispatch(addBrokerLimits(values)),
  resetForm: () => dispatch(reset('accounts'))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert(EditRisk));
