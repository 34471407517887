import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';
import { Hint, RadialChart } from 'react-vis';
import withGraph from 'components/withGraph';
import { getPercent, formatValue, FIELD_TYPE_SHARES } from 'utils/formatter';

class DarkLitGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
      dataPoint: null
    };

    this.showTooltip = this.showTooltip.bind(this);
    this.clearTooltip = this.clearTooltip.bind(this);
  }

  clearTooltip() {
    this.setState({
      showTooltip: false,
      dataPoint: null
    });
  }

  showTooltip(dataPoint) {
    this.setState({
      showTooltip: true,
      dataPoint: dataPoint
    });
  }

  render() {
    const { orders, width, height } = this.props;

    const { showTooltip, dataPoint } = this.state;

    let darkTotal = 0;
    let litTotal = 0;

    orders.forEach(row => {
      darkTotal +=
        row.DarkMake_ExecShares +
        row.DarkTake_ExecShares +
        row.DarkOther_ExecShares;
      litTotal +=
        row.LitMake_ExecShares +
        row.LitTake_ExecShares +
        row.LitOther_ExecShares;
    });

    let fullTotal = darkTotal + litTotal;

    const data = [
      {
        angle: darkTotal,
        label: getPercent(darkTotal, fullTotal),
        fullTotal: formatValue(fullTotal, FIELD_TYPE_SHARES),
        total: formatValue(darkTotal, FIELD_TYPE_SHARES),
        name: 'Dark Total',
        color: '#0A204C'
      },
      {
        angle: litTotal,
        label: getPercent(litTotal, fullTotal),
        fullTotal: formatValue(fullTotal, FIELD_TYPE_SHARES),
        total: formatValue(litTotal, FIELD_TYPE_SHARES),
        name: 'Lit Total',
        color: '#4CA9AD'
      }
    ];

    return (
      <React.Fragment>
        {fullTotal > 0 && (
          <div>
            <RadialChart
              colorType={'literal'}
              showLabels={true}
              data={data}
              height={height}
              width={width}
              labelsRadiusMultiplier={0.9}
              labelsStyle={{ fill: '#FFFFFF' }}
              onValueMouseOver={this.showTooltip}
              onValueMouseOut={this.clearTooltip}
            >
              {showTooltip && (
                <Hint value={dataPoint}>
                  <div className="mark-series-hint">
                    <p>
                      {dataPoint.name}: {dataPoint.total}
                    </p>
                    <p>Full Total: {dataPoint.fullTotal}</p>
                    <p>Percentage: {dataPoint.label}</p>
                  </div>
                </Hint>
              )}
            </RadialChart>
            <ul className="color-legend list-unstyled color-legend-dark">
              <li>Dark</li>
              <li>Lit</li>
            </ul>
          </div>
        )}
      </React.Fragment>
    );
  }
}

DarkLitGraph.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired,
  orders: PropTypes.array.isRequired
};

export default withGraph(DarkLitGraph);
