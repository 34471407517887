import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import RiskHeader from './RiskHeader';

function RiskHeaders({ riskTypes }) {
  return (
    <div className="risk-headers">
      <Row>
        <RiskHeader
          id="risk-header-account-title"
          title="Mnemonic"
          className="risk-account-header"
        />
        {riskTypes.map((riskType, key) => {
          return (
            <RiskHeader
              title={riskType.name}
              helpText={riskType.help_text}
              id={`risk-header-${riskType.id.toString()}`}
              key={key}
            />
          );
        })}
      </Row>
    </div>
  );
}

RiskHeaders.propTypes = {
  riskTypes: PropTypes.array.isRequired
};

export default RiskHeaders;
