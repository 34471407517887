import React from 'react';
import './ClientName.css';

function ClientName({ name }) {
  return (
    <div className="client-name">
      <h3 className="text-center">{name}</h3>
    </div>
  );
}

export default ClientName;
