import ClientName from '../components/ClientName';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  name:
    state.user &&
    state.user.data &&
    state.user.data.client &&
    state.user.data.client.fullName
});

export default connect(
  mapStateToProps,
  null
)(ClientName);
