import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Col } from 'reactstrap';
import './TotalData.css';
import Statistic from './Statistic';
import _ from 'underscore';
import {
  ORDER_STATUS_OPEN,
  ORDER_STATUS_COMPLETE,
  ORDER_STATUS_REPLACED
} from 'utils/constants';
import { FIELD_TYPE_DOLLAR, FIELD_TYPE_SHARES } from 'utils/formatter';

function TotalData({ orders }) {
  let ordersState = _.countBy(orders, order => {
    return order.Status === ORDER_STATUS_OPEN;
  });

  let ordersSide = _.groupBy(orders, order => {
    if (order.Status == ORDER_STATUS_REPLACED) {
      return ORDER_STATUS_COMPLETE;
    }
    return order.Status;
  });

  let openOrders = ordersSide[ORDER_STATUS_OPEN];
  let completeOrders = ordersSide[ORDER_STATUS_COMPLETE];

  let trueOrders =
    ordersState[true] && ordersState[true] > -1 ? ordersState[true] : 0;

  let falseOrders =
    ordersState[false] && ordersState[false] > -1 ? ordersState[false] : 0;

  let totalOrders = trueOrders + falseOrders;

  let sharesFirstTotal = reduceOrderByProp(openOrders, 'OrderQty');
  let sharesSecondTotal = reduceOrderByProp(completeOrders, 'OrderQty');

  let costFirstTotal = sumOrderProps(openOrders, orderCostProps);
  let costSecondTotal = sumOrderProps(completeOrders, orderCostProps);

  let notationalFirstTotal = reduceOrderByProp(openOrders, 'NotionalValue');
  let notationalSecondTotal = reduceOrderByProp(
    completeOrders,
    'NotionalValue'
  );

  let costPerSharesOpen = (costFirstTotal / sharesFirstTotal) * 1000;
  let costPerSharesClosed = (costSecondTotal / sharesSecondTotal) * 1000;

  return (
    <Row className="total-data">
      <Col sm={12}>
        <Card>
          <div>
            <Statistic
              title="Total Orders"
              firstTotal={totalOrders}
              showTotal={false}
            />
          </div>
          <div className="separator" />
          <div>
            <Statistic
              title="Orders (Open/Closed)"
              showTotal={false}
              firstTotal={trueOrders}
              secondTotal={falseOrders}
            />
          </div>
          <div className="separator" />
          <div>
            <Statistic
              title="Shares (Open/Closed)"
              showTotal={false}
              firstTotal={sharesFirstTotal}
              secondTotal={sharesSecondTotal}
              formatType={FIELD_TYPE_SHARES}
            />
          </div>
          <div className="separator" />
          <div>
            <Statistic
              title="Notional (Open/Closed)"
              showTotal={false}
              firstTotal={notationalFirstTotal}
              secondTotal={notationalSecondTotal}
              formatType={FIELD_TYPE_DOLLAR}
              fractionDigits="0"
              minFractionDigits="0"
            />
          </div>
          <div className="separator" />
          <div>
            <Statistic
              title="Cost (Open/Closed)"
              showTotal={false}
              firstTotal={costFirstTotal}
              secondTotal={costSecondTotal}
              formatType={FIELD_TYPE_DOLLAR}
              fractionDigits="2"
              minFractionDigits="2"
            />
          </div>
          <div className="separator" />
          <div>
            <Statistic
              title="Cost Per Share in Mils"
              showTotal={false}
              firstTotal={isNaN(costPerSharesOpen) ? 0 : costPerSharesOpen}
              secondTotal={isNaN(costPerSharesClosed) ? 0 : costPerSharesClosed}
              fractionDigits="1"
              minFractionDigits="1"
            />
          </div>
        </Card>
      </Col>
      <Col sm={12}>
        <Card>
          <Statistic
            title="Interval VWAP (bips) (Total)"
            showTotal={false}
            firstTotal={weightedAverage(
              orders,
              'AvgPriceToVwapRefBibs',
              'OrderQty'
            )}
            fractionDigits="2"
            minFractionDigits="2"
          />
          <div className="separator" />
          <Statistic
            title="Interval VWAP (bips) (Open/Closed)"
            showTotal={false}
            firstTotal={weightedAverage(
              openOrders,
              'AvgPriceToVwapRefBibs',
              'OrderQty'
            )}
            secondTotal={weightedAverage(
              completeOrders,
              'AvgPriceToVwapRefBibs',
              'OrderQty'
            )}
            fractionDigits="2"
            minFractionDigits="2"
          />
          <div className="separator" />
          <Statistic
            title="Arrival Px (bips) (Total)"
            showTotal={true}
            firstTotal={weightedAverage(
              orders,
              'AvgPriceToRefBibs',
              'OrderQty'
            )}
            fractionDigits="2"
            minFractionDigits="2"
          />
          <div className="separator" />
          <Statistic
            title="Arrival Px (bips) (Open/Closed)"
            showTotal={false}
            firstTotal={weightedAverage(
              openOrders,
              'AvgPriceToRefBibs',
              'OrderQty'
            )}
            secondTotal={weightedAverage(
              completeOrders,
              'AvgPriceToRefBibs',
              'OrderQty'
            )}
            fractionDigits="2"
            minFractionDigits="2"
          />
        </Card>
      </Col>
    </Row>
  );
}

const orderCostProps = [
  'LitMake_Fee',
  'LitTake_Fee',
  'LitOther_Fee',
  'DarkMake_Fee',
  'DarkTake_Fee',
  'DarkOther_Fee'
];

const weightedAverage = (orders, averageProp, weightProp) => {
  let weight = reduceOrderByProp(orders, weightProp);

  let result = _.reduce(
    orders,
    (sum, order) => {
      return sum + order[averageProp] * order[weightProp];
    },
    0
  );

  let value = result / weight;

  if (isNaN(value)) {
    return 0;
  } else {
    return value;
  }
};

const sumOrderProps = (orders, props) => {
  if (!Array.isArray(props)) {
    props = [props];
  }

  return _.reduce(
    orders,
    (sum, order) => {
      let subTotal = 0;

      props.forEach(prop => {
        if (order[prop]) {
          subTotal += Number.parseFloat(order[prop]);
        }
      });

      return sum + subTotal;
    },
    0
  );
};

const reduceOrderByProp = (orders, prop) => {
  return orders && orders.length > -1
    ? orders.reduce((s, o) => {
        return s + Number.parseFloat(o[prop]);
      }, 0)
    : null;
};

TotalData.propTypes = {
  orders: PropTypes.array.isRequired
};

export default TotalData;
