import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function Input({
  input,
  type,
  prefix,
  suffix,
  onIncrement,
  onDecrement,
  meta: { touched, error, dirty }
}) {
  return (
    <div>
      <div className="input-group">
        <NumberFormat
          {...input}
          className={`form-control number-input ${dirty ? 'dirty' : ''}`}
          thousandSeparator={true}
          prefix={prefix}
          suffix={suffix}
        />
        <div className="input-group-append">
          <button
            onClick={() => {
              onDecrement && onDecrement();
            }}
            className="btn btn-outline-secondary"
            type="button"
          >
            -
          </button>
          <button
            onClick={() => {
              onIncrement && onIncrement();
            }}
            className="btn btn-outline-secondary"
            type="button"
          >
            +
          </button>
        </div>
      </div>
      {touched && error && <span>{error}</span>}
    </div>
  );
}

Input.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func
};

export default Input;
