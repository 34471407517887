import React, { Component } from 'react';
import PricesGraph1 from './PricesGraph1';
import PricesGraph2 from './PricesGraph2';
import PricesGraph3 from './PricesGraph3';
import moment from 'moment';
import PropType from 'prop-types';
import { Button } from 'reactstrap';
import GraphFilter from 'components/GraphFilter';
import withGraph from 'components/withGraph';

const yPadding = 0.05;

const colors = [
  '#bdc3c7', // 0
  '#26f4ba', // 1
  '#07748e', // 2
  '#59b89e', // 3
  '#19326e', // 4
  '#e80c0c', // 5 - candlestick - closed lower
  '#00ad0c' // 6 - candlestick - closed higher
];

class PricesGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeFrame: {
        min: 0,
        max: 1
      },
      dataSource5_marketPrice: [],
      dataSource5: [],
      dataSource1_executionPrice: [],
      dataSource7_VWAPPrice: [],
      dataSource7_averagePrice: [],
      graphState: {
        averagePrice: true,
        vwapPrice: true,
        referencePrice: true,
        marketPrice: true,
        executionPrice: true
      },
      topMost: null,
      bottomMost: null
    };
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    const {
      dataSource1,
      dataSource5,
      dataSource7,
      storeDefaultTimeFrame,
      defaultTimeFrameSet,
      storeDefaultHighLow,
      storeHighLow,
      getRecentQuarter,
      getNextQuarter
    } = nextProps;

    let newState = {
      ...previousState,
      ...nextProps
    };

    let lowestPoint = null;
    let highestPoint = 0;

    if (
      dataSource1 &&
      dataSource1.data.length !==
        previousState.dataSource1_executionPrice.length
    ) {
      let dataSource1_executionPrice = dataSource1.data.map(row => {
        return {
          x: parseActivityTime(row.ActivityTime),
          y: row.ExecutionPrice,
          size: row.ExecutionVolume,
          payload: row
        };
      });

      newState = Object.assign({}, newState, {
        dataSource1_executionPrice
      });
    }

    if (
      dataSource5 &&
      dataSource5.data.length !== previousState.dataSource5_marketPrice.length
    ) {
      let minTime = null;
      let maxTime = 0;

      let dataSource5_marketPrice = dataSource5.data.map(row => {
        let activityTime = parseActivityTime(row.ActivityTime);

        if (minTime === null || activityTime < minTime) {
          minTime = activityTime;
        }

        if (activityTime > maxTime) {
          maxTime = activityTime;
        }

        if (lowestPoint === null || (row.Low < lowestPoint && row.Low !== 0)) {
          lowestPoint = row.Low;
        }

        if (row.High > highestPoint) {
          highestPoint = row.High;
        }

        return {
          yLow: row.Low,
          yHigh: row.High,
          yOpen: row.EntryPrice,
          yClose: row.ExitPrice,
          x: activityTime,
          y: (row.EntryPrice + row.ExitPrice) / 2
        };
      });

      if (
        dataSource7 &&
        dataSource7.data.length !==
          previousState.dataSource7_averagePrice.length
      ) {
        let dataSource7_averagePrice = dataSource7.data.map(row => {
          if (
            lowestPoint === null ||
            (row.AvgPrice < lowestPoint && row.AvgPrice !== 0)
          ) {
            lowestPoint = row.AvgPrice;
          }

          if (row.AvgPrice > highestPoint) {
            highestPoint = row.AvgPrice;
          }

          return {
            x: parseActivityTime(row.ActivityTime),
            y: row.AvgPrice
          };
        });

        let dataSource7_VWAPPrice = dataSource7.data.map(row => {
          if (
            lowestPoint === null ||
            (row.VWAPPrice < lowestPoint && row.VWAPPrice !== 0)
          ) {
            lowestPoint = row.VWAPPrice;
          }

          if (row.VWAPPrice > highestPoint) {
            highestPoint = row.VWAPPrice;
          }

          return {
            x: parseActivityTime(row.ActivityTime),
            y: row.VWAPPrice
          };
        });

        newState = Object.assign({}, newState, {
          dataSource7_averagePrice,
          dataSource7_VWAPPrice
        });
      }

      newState = Object.assign({}, newState, {
        dataSource5_marketPrice
      });

      minTime = getRecentQuarter(moment(minTime));
      maxTime = getNextQuarter(moment(maxTime));

      if (!defaultTimeFrameSet) {
        storeDefaultTimeFrame(
          Number.parseFloat(minTime),
          Number.parseFloat(maxTime)
        );
      }

      let finalHighestPoint = highestPoint + yPadding;
      let finalLowestPoint = lowestPoint - yPadding;

      storeDefaultHighLow(finalHighestPoint, finalLowestPoint);
      storeHighLow(finalHighestPoint, finalLowestPoint);
    }

    return newState;
  }

  onFilter = graph => {
    this.setState({
      graphState: {
        ...this.state.graphState,
        [graph]: !this.state.graphState[graph]
      }
    });
  };

  testDataRange(topMost, bottomMost, data) {
    const { timeFrame } = this.state;

    data.map(dataPoint => {
      if (dataPoint.x >= timeFrame.min && dataPoint.x <= timeFrame.max) {
        if (topMost === null || topMost < dataPoint.y) {
          topMost = dataPoint.y;
        }
        if (bottomMost === null || bottomMost > dataPoint.y) {
          bottomMost = dataPoint.y;
        }
      }
    });

    return { topMost: topMost, bottomMost: bottomMost };
  }

  setHighLow = () => {
    const {
      dataSource1_executionPrice,
      dataSource7_averagePrice,
      dataSource7_VWAPPrice,
      dataSource5_marketPrice,
      storeHighLow,
      timeFrame
    } = this.state;

    let topMost = null;
    let bottomMost = null;

    let dataSourceResults = this.testDataRange(
      topMost,
      bottomMost,
      dataSource1_executionPrice
    );
    topMost = dataSourceResults.topMost;
    bottomMost = dataSourceResults.bottomMost;

    dataSourceResults = this.testDataRange(
      topMost,
      bottomMost,
      dataSource7_averagePrice
    );
    topMost = dataSourceResults.topMost;
    bottomMost = dataSourceResults.bottomMost;

    dataSourceResults = this.testDataRange(
      topMost,
      bottomMost,
      dataSource7_VWAPPrice
    );
    topMost = dataSourceResults.topMost;
    bottomMost = dataSourceResults.bottomMost;

    dataSource5_marketPrice.map(dataPoint => {
      if (dataPoint.x >= timeFrame.min && dataPoint.x <= timeFrame.max) {
        if (topMost === null || topMost < dataPoint.yHigh) {
          topMost = dataPoint.yHigh;
        }
        if (bottomMost === null || bottomMost > dataPoint.yLow) {
          bottomMost = dataPoint.yLow;
        }
      }
    });

    if (topMost != bottomMost) {
      storeHighLow(topMost + yPadding, bottomMost - yPadding);
    }
  };

  render() {
    const {
      dataSource4,
      timeFrame,
      storeTimeFrame,
      resetTimeFrame,
      yAxis,
      width
    } = this.props;

    const {
      dataSource1_executionPrice,
      dataSource7_averagePrice,
      dataSource7_VWAPPrice,
      dataSource5_marketPrice,
      graphState
    } = this.state;

    return (
      <div className="graph multi-graph prices-graph">
        <PricesGraph1
          size={1}
          dataSource1_executionPrice={dataSource1_executionPrice}
          dataSource4={dataSource4.data}
          timeFrame={timeFrame}
          dataSource5_marketPrice={dataSource5_marketPrice}
          yAxis={yAxis}
          colors={colors}
          enableExecutionPrice={graphState.executionPrice}
          enableReferencePrice={graphState.referencePrice}
          enableMarketPrice={graphState.marketPrice}
          width={width}
        />
        <PricesGraph2
          size={1}
          dataSource7_averagePrice={dataSource7_averagePrice}
          dataSource7_VWAPPrice={dataSource7_VWAPPrice}
          timeFrame={timeFrame}
          yAxis={yAxis}
          colors={colors}
          vwapPriceEnabled={graphState.vwapPrice}
          averagePriceEnabled={graphState.averagePrice}
          width={width}
        />
        <PricesGraph3
          size={1}
          dataSource1_executionPrice={dataSource1_executionPrice}
          timeFrame={timeFrame}
          storeTimeFrame={storeTimeFrame}
          resetTimeFrame={resetTimeFrame}
          setHighLow={this.setHighLow}
          yAxis={yAxis}
          colors={colors}
          enableExecutionPrice={graphState.executionPrice}
          width={width}
        />
        <div className="legend">
          <div className="items">
            <GraphFilter
              color={colors[0].substr(1, 6)}
              label="Average Price"
              onChange={() => this.onFilter('averagePrice')}
              shown={graphState.averagePrice}
            />
            <GraphFilter
              color={colors[1].substr(1, 6)}
              label="VWAP"
              onChange={() => this.onFilter('vwapPrice')}
              shown={graphState.vwapPrice}
            />
            <GraphFilter
              color={colors[2].substr(1, 6)}
              label="Reference Price"
              onChange={() => this.onFilter('referencePrice')}
              shown={graphState.referencePrice}
            />
            <GraphFilter
              color={colors[3].substr(1, 6)}
              label="Market Data"
              onChange={() => this.onFilter('marketPrice')}
              shown={graphState.marketPrice}
            />
            <GraphFilter
              color={colors[4].substr(1, 6)}
              label="Execution"
              onChange={() => this.onFilter('executionPrice')}
              shown={graphState.executionPrice}
            />
          </div>
        </div>
        <Button onClick={resetTimeFrame}>Reset Zoom</Button>
      </div>
    );
  }
}

PricesGraph.propTypes = {
  dataSource1: PropType.object.isRequired,
  dataSource4: PropType.object.isRequired,
  dataSource5: PropType.object.isRequired,
  dataSource7: PropType.object.isRequired,
  width: PropType.number.isRequired
};

export function parseActivityTime(activityTime, format = 'HH:mm:ss.SSS') {
  return Number.parseFloat(moment(activityTime, format).format('x'));
}

export default withGraph(PricesGraph);
