import React from 'react';
import EditRisk from '../routes/Edit';
import { Redirect, Route, Switch } from 'react-router';
import Confirm from '../routes/Confirm';

function Risk({ match, hasRiskAccess }) {
  if (!hasRiskAccess) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: '/risk' }
        }}
      />
    );
  }

  return (
    <Switch>
      <Route
        exact
        key="risk_edit"
        path={`${match.path}/edit`}
        component={EditRisk}
      />
      <Route
        exact
        key="risk_confirm"
        path={`${match.path}/confirm`}
        component={Confirm}
      />
      <Redirect from={`${match.path}`} to={`${match.path}/edit`} />
    </Switch>
  );
}

export default Risk;
