import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadMyUser } from 'reducers/User';
import {
  hasRole,
  ROLE_RISK_USER,
  isAuthenticated,
  ROLE_CLIENT_USER
} from 'utils/auth';
import { compose } from 'redux';

function withUserProfile(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      const { loadUser, loaded, loading } = this.props;

      if (isAuthenticated() && !loaded && !loading) {
        loadUser();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

const mapStateToProps = state => ({
  state: state,
  user: state.user.data,
  loading: state.user.loading,
  loaded: state.user.sync,
  isAuthenticated:
    isAuthenticated() && hasRole(state.user.data, ROLE_CLIENT_USER),
  hasRiskAccess: hasRole(state.user.data, ROLE_RISK_USER)
});

const mapDispatchToProps = dispatch => ({
  loadUser: () => {
    dispatch(loadMyUser());
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withUserProfile
);
