import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PageTitle from 'components/PageTitle';
import DateSelector from 'components/DateSelector';
import moment from 'moment';
import './NRoute.css';
import {
  InputGroup,
  Input,
  Table,
  Button,
  InputGroupAddon,
  Row,
  Col,
  Label
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

class Orders extends Component {
  constructor(props) {
    super(props);

    const now = moment();

    this.state = {
      order: '',
      date: now,
      csvData: []
    };
  }

  componentDidMount() {
    const {
      loadDataSource,
      match: {
        params: { date, order_id }
      }
    } = this.props;

    if (date && order_id) {
      loadDataSource(date, order_id);
    }
  }

  onDateSelect = date => {
    this.setState({
      date: moment(date)
    });
  };

  onOrderUpdate = event => {
    let value = event.target.value;

    value = value.replace('_CODA', '');

    this.setState({
      order: value
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      dataSource10: { data }
    } = this.props;

    if (data !== prevProps.dataSource10.data) {
      let rows = [];

      data.forEach(element => {
        rows.push([element.title, ...element.rows]);
      });

      this.setState({
        csvData: rows
      });
    }
  }

  componentWillUnmount() {
    const { resetDataSource } = this.props;

    resetDataSource();

    this.setState({
      order: ''
    });
  }

  onSubmit = () => {
    const { date, order } = this.state;
    const { loadDataSource } = this.props;

    if (date && order) {
      loadDataSource(date.format('YYYY-MM-DD'), order + '_CODA');
    }
  };

  render() {
    const {
      dataSource10: { data }
    } = this.props;

    const { date, order, csvData } = this.state;

    return (
      <div className="order-nroute">
        <Helmet>
          <title>NRoute</title>
        </Helmet>
        <PageTitle title={`${moment(date).format('dddd, MMMM Do YYYY')}`}>
          <DateSelector date={date} onDateSelect={this.onDateSelect} />
        </PageTitle>
        <Row className="title-inputs">
          <Col sm={{ size: 6 }}>
            <InputGroup>
              <Label htmlFor="order_id">Order Number</Label>
              <Input
                id="order_id"
                type="number"
                value={order}
                onChange={this.onOrderUpdate}
              />
              <InputGroupAddon addonType="append">_CODA</InputGroupAddon>
              <Button color="secondary" onClick={this.onSubmit}>
                Lookup Order
              </Button>
            </InputGroup>

            <Table striped className="nroute_table_data">
              <tbody>
                {data &&
                  Array.isArray(data) &&
                  data.length > 0 && (
                    <CSVLink
                      data={csvData}
                      filename={`${order}_CODA ${date.format(
                        'MMMM Do YYYY'
                      )}.csv`}
                    >
                      Download
                      <FontAwesomeIcon icon={faDownload} />
                    </CSVLink>
                  )}
                {data &&
                  Array.isArray(data) &&
                  data.map((element, key) => {
                    return (
                      <tr key={`nroute_data_${key}`}>
                        <td>
                          {element.title}
                          <ul>
                            {element.rows.map((row, rKey) => {
                              return (
                                <li key={`nroute_data_${key}_element_${rKey}`}>
                                  {row}
                                </li>
                              );
                            })}
                          </ul>
                        </td>
                      </tr>
                    );
                  })}

                {data &&
                  (Array.isArray(data) && data.length === 0) && (
                    <p>No Data Found</p>
                  )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Orders;
