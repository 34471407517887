import React from 'react';
import Auth from 'components/Auth';
import Helmet from 'react-helmet';

function ResultMessage({
  helmet,
  background,
  title,
  header,
  body,
  actionLinkUrl,
  actionLinkText
}) {
  return (
    <Auth title={title}>
      <div className="password-interior-container">
        <Helmet>
          <title>{helmet}</title>
          <body className={background} />
        </Helmet>
        <div className="password-text-container">
          <h2 dangerouslySetInnerHTML={{ __html: header }} />
          <p dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        <div className="return-home-link">
          <a href={actionLinkUrl}>{actionLinkText}</a>
        </div>
      </div>
    </Auth>
  );
}

ResultMessage.propTypes = {};

export default ResultMessage;
