import Header from 'components/Header';
import { connect } from 'react-redux';
import { hasRole, ROLE_RISK_USER, ROLE_GLP_ADMIN } from 'utils/auth';
import withUserProfile from 'containers/withUserProfile';
import { compose } from 'redux';
import { getUserData } from 'utils/auth';

const mapStateToProps = state => ({
  hasRiskAccess: hasRole(state.user.data, ROLE_RISK_USER),
  isGlpAdmin: hasRole(state.user.data, ROLE_GLP_ADMIN),
  clientName: getUserData() && getUserData().client_name,
  clientId: getUserData() && getUserData().client_id
});

const enhance = compose(
  withUserProfile,
  connect(
    mapStateToProps,
    null
  )
);

export default enhance(Header);
