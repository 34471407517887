import React, { Component } from 'react';
import _ from 'underscore';
import ConfirmationForm from '../components/ConfirmationForm';
import { request } from 'utils/fetch';
import { reduxForm } from 'redux-form';

const VALIDATION_TYPE_BROKER_LEVEL = 'broker_level_validation';
const VALIDATION_TYPE_MAX_ORDER = 'max_order_validation';

const VALIDATION_TYPE_MESSAGES = {
  [VALIDATION_TYPE_BROKER_LEVEL]:
    'The value you have submitted exceed the recommended limit, please verify them before submitting',
  [VALIDATION_TYPE_MAX_ORDER]:
    'This change does not match the default values, please verify them before submitting'
};

class Confirm extends Component {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      values: []
    };
  }

  onSubmit(reasonForChange) {
    const { values } = this.state;

    const {
      alert,
      history: { push },
      appReset
    } = this.props;

    const body = {
      values: values,
      reasonForChange: reasonForChange.reasonForChange
    };

    request('/api/accounts', {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(data => {
        appReset();
        alert.success('New Risk Values Submitted!');
      })
      .catch(() => {
        alert.error('Error while submitting new values!');
      })
      .finally(() => {
        push('/risk');
      });
  }

  onCancel() {
    const {
      history: { goBack },
      appReset
    } = this.props;

    appReset(); // <--- this works, but resets any changes they had made.  Maybe they only made one mistake out of many changes!

    goBack();
  }

  componentDidMount() {
    const {
      initialValues,
      newValues,
      accounts,
      riskTypes,
      limits,
      history: { push }
    } = this.props;

    const keys = Object.keys(newValues);

    let diffValues = [];

    let sums = [];

    keys.forEach(key => {
      const keyParts = key.split('_');

      if (keyParts.length !== 2) {
        return;
      }

      if (!sums.hasOwnProperty(keyParts[1])) {
        sums[keyParts[1]] = 0;
      }
      sums[keyParts[1]] += parseInt(newValues[key]);
    });

    keys.forEach(key => {
      if (newValues[key] !== initialValues[key]) {
        const keyParts = key.split('_');

        if (keyParts.length !== 2) {
          return;
        }

        let riskType = _.find(riskTypes, r => r.id.toString() === keyParts[1]);
        let limit = _.find(limits, l => l.risk_type.id === riskType.id);

        let alert = null;

        let limitValue = parseInt(limit.value);

        switch (riskType.validation_type) {
          case VALIDATION_TYPE_MAX_ORDER:
            if (newValues[key] > limitValue) {
              alert = VALIDATION_TYPE_MESSAGES[VALIDATION_TYPE_MAX_ORDER];
            }
            break;

          case VALIDATION_TYPE_BROKER_LEVEL:
            if (sums[keyParts[1]] > limitValue)
              alert = VALIDATION_TYPE_MESSAGES[VALIDATION_TYPE_BROKER_LEVEL];
            break;

          default:
            alert = null;
        }

        diffValues.push({
          account: _.find(accounts, a => a.id.toString() === keyParts[0]),
          riskType: riskType,
          oldValue: initialValues[key],
          newValue: newValues[key],
          alert: alert
        });
      }
    });

    if (diffValues.length === 0) {
      push('/');
    }

    this.setState({ values: diffValues });
  }

  render() {
    const { values } = this.state;

    return (
      <ConfirmationForm
        values={values}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        handleSubmit={this.props.handleSubmit}
      />
    );
  }
}

export default reduxForm({
  form: 'accounts',
  enableReinitialize: true
})(Confirm);
