import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import RiskValue from '../components/RiskValue';

const mapSateToProps = (state, ownProps) => {
  const fieldName = `${ownProps.account.id}_${ownProps.riskValue.risk_type.id}`;
  const selector = formValueSelector('accounts');

  return {
    fieldName: fieldName,
    fieldValue: selector(state, fieldName)
  };
};

const mapDispatchToProps = dispatch => ({
  changeField: (field, value) => dispatch(change('accounts', field, value))
});

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(RiskValue);
