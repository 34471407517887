import React from 'react';
import {
  FormGroup,
  Label,
  InputGroup,
  Input as ReactstrapInput
} from 'reactstrap';
import './Input.css';
import PropTypes from 'prop-types';

function Input({
  id,
  input,
  label,
  type,
  disabled,
  placeholder,
  meta: { touched, error, warning },
  prependedAddon = '',
  appendedAddon = ''
}) {
  return (
    <FormGroup
      className={`${touched && error ? 'has-error' : ''} ${
        touched && warning ? 'has-warning' : ''
      }`}
    >
      {label && (
        <Label className="control-label" htmlFor={id}>
          {label}
        </Label>
      )}
      {prependedAddon || appendedAddon ? (
        <InputGroup>
          {prependedAddon}
          <ReactstrapInput
            id={id}
            className="form-control"
            {...input}
            placeholder={placeholder ? placeholder : label}
            type={type}
            disabled={disabled}
          />
          {appendedAddon}
        </InputGroup>
      ) : (
        <ReactstrapInput
          id={id}
          className="form-control"
          {...input}
          placeholder={placeholder ? placeholder : label}
          type={type}
          disabled={disabled}
        />
      )}
      {touched &&
        ((error && <span className="help-block">{error}</span>) ||
        (warning && <span className="help-block">{warning}</span>))
      }
    </FormGroup>
  );
}

export default Input;

Input.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  })
};
