import React from 'react';

import Textarea from './Textarea';
import { Field } from 'redux-form';

function ReasonForChange() {
  return (
    <Field
      name="reasonForChange"
      type="text"
      component={Textarea}
      label={false}
    />
  );
}

ReasonForChange.propTypes = {};

export default ReasonForChange;
