import React, { Component } from 'react';
import { Hint, RadialChart } from 'react-vis/es';
import PropTypes from 'prop-types';

class PieChartGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataPoint: null,
      showTooltip: false
    };

    this.showTooltip = this.showTooltip.bind(this);
    this.clearTooltip = this.clearTooltip.bind(this);
  }

  showTooltip(dataPoint) {
    this.setState({
      dataPoint,
      showTooltip: true
    });
  }

  clearTooltip() {
    this.setState({
      dataPoint: null,
      showTooltip: false
    });
  }

  render() {
    const { width, height, values, children, hint, hideLegend } = this.props;
    const { dataPoint, showTooltip } = this.state;

    return (
      <div className="pie-graph">
        <div className="legend">
          {!hideLegend && (
            <ul>
              {values.map((element, key) => {
                return (
                  <li key={key} style={{ color: element.color }}>
                    {element.key}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <RadialChart
          colorType="literal"
          showLabels={false}
          data={values}
          height={height}
          width={width - 300}
          onValueMouseOver={this.showTooltip}
          onValueMouseOut={this.clearTooltip}
        >
          {showTooltip &&
            hint && <Hint value={dataPoint}>{hint(dataPoint)}</Hint>}
        </RadialChart>
        <div className="graph-actions">{children}</div>
      </div>
    );
  }
}

PieChartGraph.propTypes = {
  values: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  hint: PropTypes.func,
  hideLegend: PropTypes.bool
};

export default PieChartGraph;
