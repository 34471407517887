import React from 'react';
import PropTypes from 'prop-types';

function Textarea ({input}) {
  return (
    <div className="input-group">
      <textarea className="form-control" {...input} />
    </div>
  );
}

Textarea.propTypes = {
  input: PropTypes.object
};

export default Textarea;
