import React from 'react';
import { Container } from 'reactstrap';
import { Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './containers/Header';
import Footer from './components/Footer';
import SessionTimer from './components/SessionTimer';
import routes from './routes';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import './App.css';

const options = {
  position: 'top right',
  timeout: 5000,
  offset: '60px 30px',
  transition: 'scale'
};

function App() {
  return (
    <div className="App">
      <Helmet titleTemplate="%s | GLP Portal">
        <title>App</title>
      </Helmet>
      <div className="gradient" />
      <Header />
      <AlertProvider template={AlertTemplate} {...options}>
        <Container fluid className="app-container">
          <SessionTimer />
          <Switch>{routes}</Switch>
        </Container>
      </AlertProvider>
      <Footer />
    </div>
  );
}

export default App;
