import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import './Auth.css';
import Icon from './GLP_icon.png';

function Auth({ title, children }) {
  return (
    <Col xs={12} sm={{ size: 8, offset: 2 }} className="auth auth-container">
      {title && (
        <Row>
          <h1 className="login-header">
            <img src={Icon} /> {title}
          </h1>
        </Row>
      )}
      <Row>{children}</Row>
    </Col>
  );
}

Auth.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.isRequired
};

export default Auth;
