import NRoute from '../components';
import { connect } from 'react-redux';
import dataSourceRest from 'redux/modules/dataSourceRest';
import { push } from 'react-router-redux';
import { resetDataStore } from 'reducers/dataStoreReducer';

const mapStateToProps = state => {
  return {
    dataSource10: state.dataStore.dataSource10
  };
};

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  loadDataSource: (date, orderId) => {
    return Promise.all([
      dispatch(dataSourceRest.actions.dataSource10({ date, orderId }))
    ]);
  },
  resetDataSource: () => {
    dispatch(resetDataStore());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NRoute);
