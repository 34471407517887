import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withGraph from 'components/withGraph';
import _ from 'underscore';
import ColorGenerator from 'utils/colorGenerator';
import Select from 'react-select';
import 'rc-slider/assets/index.css';
import { Range } from 'rc-slider';
import { parseActivityTime } from './PricesGraph';
import PieChartGraph from './PieChartGraph';
import { formatValue, FIELD_TYPE_PERCENT } from 'utils/formatter';
import MultiValueLabel from 'components/MultiValueLabel';

class LiquidityModeGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      values: [],
      priceQualityRange: {
        min: null,
        max: null
      },
      selectedLastMarketType: null
    };

    this.updatePriceQualityRange = this.updatePriceQualityRange.bind(this);
    this.onSelectLastMarket = this.onSelectLastMarket.bind(this);
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    const { dataSource1, timeFrame } = nextProps;

    let newState = {
      ...previousState,
      ...nextProps
    };

    if (dataSource1 && dataSource1.length !== previousState.data.length) {
      //Filtering for selected TimeFrame.
      let data = _.filter(dataSource1, item => {
        let activityTime = parseActivityTime(item.ActivityTime);

        return activityTime >= timeFrame.min && activityTime <= timeFrame.max;
      });

      let lastMarketOptions = _.uniq(data, item => item.LastMkt).map(item => {
        return {
          value: item.LastMkt,
          label: item.LastMkt
        };
      });

      if (
        newState.selectedLastMarketType !== null &&
        newState.selectedLastMarketType.length > 0
      ) {
        data = _.filter(data, item =>
          _.contains(
            _.pluck(newState.selectedLastMarketType, 'value'),
            item.LastMkt
          )
        );
      }

      //Filtering for rows with PriceQuality within the selected range.
      data = _.filter(data, item => {
        return (
          item.PriceQuality >= newState.priceQualityRange.min &&
          item.PriceQuality <= newState.priceQualityRange.max
        );
      });

      //Grouping into chunks by Liquidity Type
      data = _.groupBy(data, item => item.LiquidityType);

      let colorGenerator = new ColorGenerator();
      let keys = Object.keys(data);
      let totalVolume = 0;

      let priceQuality = _.uniq(_.pluck(dataSource1, 'PriceQuality'));
      let minPriceQuality = Math.min(...priceQuality);
      let maxPriceQuality = Math.max(...priceQuality);

      let values = keys.map(key => {
        let color = colorGenerator.getNextColor();

        let subtotal = _.reduce(
          data[key],
          (sum, item) => {
            return sum + Number.parseFloat(item.ExecutionVolume);
          },
          0
        );

        totalVolume += subtotal;

        return {
          angle: subtotal,
          color,
          key,
          subtotal
        };
      });

      values = values.map(value => {
        return {
          ...value,
          percentage: (value.subtotal / totalVolume) * 100
        };
      });

      newState = Object.assign({}, newState, {
        data,
        values,
        minPriceQuality,
        maxPriceQuality,
        lastMarketOptions
      });

      if (
        previousState.priceQualityRange.min === null ||
        previousState.priceQualityRange.max === null
      ) {
        newState = Object.assign({}, newState, {
          priceQualityRange: {
            min: minPriceQuality,
            max: maxPriceQuality
          }
        });
      }
    }

    return newState;
  }

  onSelectLastMarket(lastMarket) {
    this.setState({
      selectedLastMarketType: lastMarket
    });
  }

  updatePriceQualityRange(range) {
    this.setState({
      priceQualityRange: {
        min: range[0],
        max: range[1]
      }
    });
  }

  render() {
    const { width, height } = this.props;
    const {
      values,
      priceQualityRange,
      minPriceQuality,
      maxPriceQuality,
      lastMarketOptions,
      selectedLastMarketType
    } = this.state;

    return (
      <PieChartGraph
        width={width}
        height={height}
        values={values}
        hint={dataPoint => (
          <div className="mark-series-hint">
            <p>Liquidity Type: {dataPoint.key}</p>
            <p>Volume: {formatValue(dataPoint.subtotal)}</p>
            <p>{formatValue(dataPoint.percentage, FIELD_TYPE_PERCENT, 2)}</p>
          </div>
        )}
      >
        <Select
          components={{ MultiValueLabel: MultiValueLabel }}
          classNamePrefix="filter-select"
          isMulti={true}
          hideSelectedOptions={false}
          value={selectedLastMarketType}
          options={lastMarketOptions}
          onChange={this.onSelectLastMarket}
          className="graph-filter-select"
        />
      </PieChartGraph>
    );
  }
}

LiquidityModeGraph.propTypes = {
  dataSource1: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  timeFrame: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }).isRequired
};

export default withGraph(LiquidityModeGraph);
