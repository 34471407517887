import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-vis/dist/style.css';
import { LARGE_GRAPH_HEIGHT } from 'components/withGraph';
import {
  XYPlot,
  MarkSeries,
  Hint,
  Highlight,
  LineSeries,
  Borders,
  XAxis,
  YAxis
} from 'react-vis';
import CustomAxisLabel from 'components/CustomAxisLabel';
import { getXAxisTimeTick } from 'utils/formatter';
import moment from 'moment';
import { formatValue, FIELD_TYPE_DOLLAR } from 'utils/formatter';

class PricesGraph3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataPoint: null,
      showToolTip: false,
      disableTooltip: false
    };

    this.clearTooltip = this.clearTooltip.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.updateDragState = this.updateDragState.bind(this);
    this.startDrag = this.startDrag.bind(this);
  }

  clearTooltip() {
    this.setState({
      showTooltip: false,
      dataPoint: null
    });
  }

  showTooltip(dataPoint) {
    if (this.state.disableTooltip) {
      return;
    }
    this.setState({
      showTooltip: true,
      dataPoint: dataPoint
    });
  }

  startDrag() {
    this.setState({
      disableTooltip: true
    });
  }

  updateDragState(area) {
    const { storeTimeFrame, setHighLow } = this.props;

    if ((area && area.left === area.right) || area === null) {
      return;
    }

    storeTimeFrame && storeTimeFrame(area.left, area.right);
    setHighLow && setHighLow();

    this.setState({
      disableTooltip: false
    });
  }

  render() {
    const { showTooltip, dataPoint, disableTooltip } = this.state;

    const {
      width,
      yAxis,
      dataSource1_executionPrice,
      timeFrame,
      resetTimeFrame,
      enableExecutionPrice
    } = this.props;

    return (
      <XYPlot
        height={LARGE_GRAPH_HEIGHT}
        width={width}
        yDomain={yAxis}
        xDomain={[timeFrame.min, timeFrame.max]}
        yBaseValue={yAxis[0]}
        onDoubleClick={resetTimeFrame}
        margin={{ left: 75, bottom: 75, right: 20 }}
        className="graph"
      >
        <Highlight
          color="#829AE3"
          enableY={false}
          onBrushStart={this.startDrag}
          onBrushEnd={this.updateDragState}
          border={50}
        />
        <Borders
          style={{
            bottom: { fill: '#fff' },
            left: { fill: '#fff' },
            right: { fill: '#fff' },
            top: { fill: '#fff' }
          }}
        />
        <XAxis tickFormat={getXAxisTimeTick} tickTotal={12} />
        <YAxis />
        <CustomAxisLabel title="Time (Eastern)" xAxis={true} />
        <CustomAxisLabel title="Curve Data" marginTop={0} />

        {/* 5. Execution Price vs Activity Time */}
        {!disableTooltip &&
          (enableExecutionPrice && (
            <MarkSeries
              color="transparent"
              data={dataSource1_executionPrice}
              onValueMouseOver={this.showTooltip}
              onValueMouseOut={this.clearTooltip}
            />
          ))}
        {/*Needed to prevent graph from hiding when all graphs are disabled.*/}
        <LineSeries data={[{ x: 0, y: 0 }, { x: 0, y: 0 }]} />
        {showTooltip && (
          <Hint value={dataPoint}>
            <div className="mark-series-hint">
              <p>
                Price:{' '}
                {formatValue(
                  dataPoint.payload.ExecutionPrice,
                  FIELD_TYPE_DOLLAR,
                  4
                )}
              </p>
              <p>Volume: {dataPoint.payload.ExecutionVolume}</p>
              <p>
                Time:{' '}
                {moment(dataPoint.payload.ActivityTime, 'HH:mm:ss.SSS').format(
                  'HH:mm'
                )}
              </p>
              <p>Last Market: {dataPoint.payload.LastMkt}</p>
              <p>Liquidity Mode: {dataPoint.payload.LiquidityType}</p>
              <p>Price Quality: {dataPoint.payload.PriceQuality}</p>
            </div>
          </Hint>
        )}
      </XYPlot>
    );
  }
}

PricesGraph3.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number.isRequired,
  dataSource1_executionPrice: PropTypes.array.isRequired,
  enableExecutionPrice: PropTypes.bool.isRequired,
  resetTimeFrame: PropTypes.func.isRequired,
  timeFrame: PropTypes.object.isRequired
};

export default PricesGraph3;
