import React from 'react';
import Auth from 'components/Auth';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from 'components/Input';
import { Form, FormGroup, Button, Alert } from 'reactstrap';
import { email, required } from 'utils/validators';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import './Login.css';

function Login({ handleSubmit, error }) {
  return (
    <Auth title="Log into your account.">
      <div className="login-container">
        <Helmet>
          <title>Login</title>
          <body className="login-background" />
        </Helmet>
        {error && <Alert color="danger">{error}</Alert>}

        <Form onSubmit={handleSubmit} className="login-form">
          <Row>
            <Col
              xs={12}
              sm={{ size: 6 }}
              lg={{ size: 5 }}
              className="login-column login-left-column"
            >
              <Field
                id="login_username"
                name="username"
                type="text"
                component={Input}
                placeholder="Username"
                validate={[required, email]}
              />
              <Field
                id="login_password"
                name="password"
                type="password"
                component={Input}
                placeholder="Password"
                validate={[required]}
              />

              <FormGroup>
                <Link
                  to="/resetting/request"
                  className="forgot-link link-secondary"
                >
                  Forgot Password?
                </Link>
              </FormGroup>
            </Col>
            <div className="spacer" />
            <Col
              xs={12}
              sm={{ size: 6 }}
              lg={{ size: 5 }}
              className="login-column"
            >
              <h2> Welcome to Global Liquidity Partners</h2>
              <p>
                Simply log in to manage and build your portfolio, and get access
                to exclusive education, objective research, and industry-leading
                trading and retirement tools.
              </p>
            </Col>
          </Row>

          <Row>
            <div className="login-controls">
              <FormGroup>
                <Button className="login-btn" color="primary" type="submit">
                  Login
                </Button>
              </FormGroup>
            </div>
          </Row>
        </Form>
      </div>
    </Auth>
  );
}

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Login;
