import React from 'react';
import { LineSeries, XYPlot, YAxis } from 'react-vis/es';
import { LARGE_GRAPH_HEIGHT } from 'components/withGraph';

function VolumeCurveGraph2({
  width,
  height,
  yAxis,
  timeFrame,
  executionVolume,
  provideQnty,
  aggrQty
}) {
  return (
    <XYPlot
      height={LARGE_GRAPH_HEIGHT}
      width={width}
      margin={{ left: 75, right: 20, top: 10, bottom: 75 }}
      yDomain={yAxis}
      xDomain={[timeFrame.min, timeFrame.max]}
      yBaseValue={yAxis[0]}
      className="graph"
    >
      {executionVolume.shown && (
        <LineSeries
          data={executionVolume.data}
          color={executionVolume.color}
          barWidth={2000}
        />
      )}
      {provideQnty.shown && (
        <LineSeries
          data={provideQnty.data}
          color={provideQnty.color}
          barWidth={2000}
        />
      )}
      {aggrQty.shown && (
        <LineSeries data={aggrQty.data} color={aggrQty.color} barWidth={2000} />
      )}
    </XYPlot>
  );
}

export default VolumeCurveGraph2;
