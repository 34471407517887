import React, { Component, createRef } from 'react';
import { Card } from 'reactstrap';
import './withGraph.css';

export const HEIGHT_TO_WIDTH_SCALE = 0.45;
export const LARGE_GRAPH_HEIGHT = 400;

const withGraph = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        width: 0,
        height: 0,
        graphRef: React.createRef()
      };
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      const { graphRef } = this.state;

      if (graphRef) {
        this.setState({
          width: graphRef.current.clientWidth,
          height: graphRef.current.clientWidth * HEIGHT_TO_WIDTH_SCALE
        });
      }
    };

    render() {
      const { height, width, graphRef } = this.state;

      return (
        <Card className="graph flex-fill">
          <div ref={graphRef}>
            <h5>{this.props.title}</h5>
            <WrappedComponent {...this.props} height={height} width={width} />
          </div>
        </Card>
      );
    }
  };
};

export default withGraph;
