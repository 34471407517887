export const FORM_LOGIN = 'login_form';
export const FORM_PROFILE = 'profile_form';
export const FORM_REQUEST_RESET = 'profile_request_pass_form';
export const FORM_RESET_PASSWORD = 'profile_reset_pass_form';
export const FORM_CHANGE_PASSWORD = 'change_password_form';

export const ORDER_STATUS_OPEN = 'Open';
export const ORDER_STATUS_COMPLETE = 'Complete';
export const ORDER_STATUS_REPLACED = 'Replaced';

export const LIQUIDITY_ALL = 'All';
export const LIQUIDITY_TAKE = 'Take';
export const LIQUIDITY_MAKE = 'Make';

export const DATE_SOURCE_DATE_FORMAT = 'YYYY-MM-DD';

export const DATA_SOURCE_EIGHT = 'dataSource8';
