export const colors = [
  '#2c2384',
  '#2f44a8',
  '#4265a5',
  '#224485',
  '#19326e',
  '#0a1f4c',
  '#07748e',
  '#04647d',
  '#025b75',
  '#00536c',
  '#004f65',
  '#004b5f',
  '#004658',
  '#004251',
  '#084234',
  '#044d3f',
  '#025245',
  '#136255',
  '#276c60',
  '#4d8076',
  '#38987e',
  '#59b89e',
  '#26f4ba'
];

class ColorGenerator {
  constructor(defaultColors = colors) {
    this.colors = defaultColors;
    this.currentColor = 0;
    this.colorCount = this.colors.length;
  }

  getNextColor() {
    if (this.currentColor + 1 < this.colorCount) {
      return this.colors[this.currentColor++];
    } else {
      this.currentColor = 0;
      return this.colors[0];
    }
  }
}

export default ColorGenerator;
