import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from './reducers';
import routeSessionUpdater from 'middleware/routeSessionUpdater';
import dataStoreMiddleware from './middleware/dataStoreMiddleware';

export const history = createHistory();
const reactRouterMiddleware = routerMiddleware(history);
const middlewares = [
  thunk,
  reactRouterMiddleware,
  routeSessionUpdater,
  dataStoreMiddleware
];

function configureStore(initialState, enhancers) {
  return createStore(rootReducer(), initialState, enhancers);
}

function configureStoreProd(initialState) {
  return configureStore(initialState, compose(applyMiddleware(...middlewares)));
}

function configureStoreDev(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = configureStore(
    initialState,
    composeEnhancers(
      applyMiddleware(...[reduxImmutableStateInvariant(), ...middlewares])
    )
  );

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const store =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd
    : configureStoreDev;

export default store;
