import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  idx: PropTypes.number.isRequired,
  renderBaseRow: PropTypes.func.isRequired
};

class RowRenderer extends Component {
  render() {
    let className;
    let referencePrice = this.props.row.ReferencePrice;
    let percentComplete = this.props.row.PercentComplete;

    if (percentComplete == 100) {
      className = 'status-percentage-complete';
    } else {
      className =
        referencePrice > 0 ? 'status-apx-positive' : 'status-apx-negative';
    }

    return (
      <div className={className}>{this.props.renderBaseRow(this.props)}</div>
    );
  }
}

RowRenderer.propTypes = propTypes;

export default RowRenderer;
