import 'isomorphic-fetch';
import reduxApi from 'redux-api';
import {
  getToken,
  isAuthenticated,
  clearSessionStorage,
  setToken
} from 'utils/auth';

const rest = reduxApi({
  login: {
    url: 'api/login_check',
    options: {
      method: 'post'
    }
  },
  requestPassResetEmail: {
    url: 'api/resetting/request-password-change',
    options: {
      method: 'post'
    }
  },
  submitNewPassword: {
    url: 'api/resetting/save-password',
    options: {
      method: 'post'
    }
  },
  user: {
    url: 'api/users/:id',
    crud: true
  },
  changePassword: {
    url: 'api/users/(:id)/change-password',
    options: {
      method: 'put'
    }
  },
  resettingRequest: {
    url: '/api/resetting/request',
    options: {
      method: 'post'
    }
  },
  resettingReset: {
    url: '/api/resetting/reset/(:token)',
    options: {
      method: 'post'
    }
  },
  riskTypes: {
    url: '/api/risk_types/(:id)',
    crud: true
  },
  clients: {
    url: '/api/clients',
    options: {
      method: 'get'
    }
  },
  refreshToken: {
    url: 'api/token/refresh',
    options: {
      method: 'post'
    }
  }
}).init(customFetch(fetch), false, process.env.REACT_APP_API_BASE_URL);

rest.use('options', options);

export function options() {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  };

  if (isAuthenticated()) {
    headers.Authorization = `Bearer ${getToken()}`;
  }

  return { headers };
}

export function customFetch(fetch) {
  return function(url, opts) {
    return fetch(url, opts).then(function(resp) {
      // Normalize IE9's response to HTTP 204 when Win error 1223.
      let status = resp.status === 1223 ? 204 : resp.status;
      let statusText = resp.status === 1223 ? 'No Content' : resp.statusText;

      return toJSON(resp).then(function(data) {
        if (status >= 200 && status < 300) {
          return data;
        } else if (status === 400) {
          return Promise.reject({ errors: data.violations });
        } else {
          if (
            (!url.includes('/login_check') &&
              (status === 401 || status === 403)) ||
            (url.includes('/users') && status === 404)
          ) {
            clearSessionStorage();
            window.location = '/login';
          }
          return Promise.reject({
            status: status,
            statusText: statusText,
            body: data
          });
        }
      });
    });
  };
}

function processData(data) {
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
}

function toJSON(resp) {
  if (resp.text) {
    return resp.text().then(processData);
  } else if (resp instanceof Promise) {
    return resp.then(processData);
  } else {
    return Promise.resolve(resp).then(processData);
  }
}

export default rest;
