import moment from 'moment';

export const FIELD_TYPE_DOLLAR = 'dollar';
export const FIELD_TYPE_PERCENT = 'percent';
export const FIELD_TYPE_SHARES = 'shares';

const getOptions = (defaults, overrides) =>
  Object.assign({}, defaults, overrides);

export const formatValue = (
  value,
  type,
  minimumFractionDigits = 0,
  maximumFractionDigits = 4
) => {
  const options = {
    grouping: true,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  };

  const valueNumber = Number(value);

  const valueGrouped = valueNumber.toLocaleString(undefined, options);

  switch (type) {
    case FIELD_TYPE_PERCENT:
      return `${valueGrouped} %`;
    case FIELD_TYPE_DOLLAR:
      let dollarOptions = getOptions(options, {
        style: 'currency',
        currency: 'USD'
      });
      return `${valueNumber.toLocaleString(undefined, dollarOptions)}`;
    case FIELD_TYPE_SHARES:
      return `${Number(valueNumber).toLocaleString(undefined)}`;

    default:
      return valueGrouped;
  }
};

export const getPercent = (runningTotal, fullTotal) => {
  let percentTotal = parseInt(Math.round((runningTotal / fullTotal) * 100));
  return formatValue(percentTotal, 'percent');
};

export const getXAxisTimeTick = v => {
  let date = moment(v, 'x');
  return date.format('HH : mm');
};

export const getRecentQuarter = timeAsMoment => {
  let currentHourFloor = timeAsMoment.format('H');

  let nearestQuarterFloor = Math.floor(timeAsMoment.format('m') / 15) * 15;

  if (currentHourFloor >= 24) {
    currentHourFloor = currentHourFloor - 24;
  }
  if (nearestQuarterFloor < 10) {
    nearestQuarterFloor = '0' + nearestQuarterFloor;
  }

  return moment(
    currentHourFloor + ':' + nearestQuarterFloor,
    'HH:mm'
  ).valueOf();
};

export const getNextQuarter = timeAsMoment => {
  let currentHourCeil = timeAsMoment.format('H');

  let nearestQuarterCeil = Math.ceil(timeAsMoment.format('m') / 15) * 15;

  if (nearestQuarterCeil >= 60) {
    currentHourCeil++;
    nearestQuarterCeil = 0;
  }

  if (currentHourCeil >= 24) {
    currentHourCeil = currentHourCeil - 24;
  }
  if (nearestQuarterCeil < 10) {
    nearestQuarterCeil = '0' + nearestQuarterCeil;
  }

  return moment(currentHourCeil + ':' + nearestQuarterCeil, 'HH:mm').valueOf();
};
