import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RadioFilter extends Component {
  render() {
    const { isSelected, onChangeFunction, value } = this.props;
    return (
      <div className="filter-item">
        <input
          type="radio"
          value={value.key}
          id={value.key}
          checked={isSelected}
          onChange={onChangeFunction}
        />
        <label onClick={onChangeFunction} htmlFor={value.key}>
          {value.label}
        </label>
      </div>
    );
  }
}

RadioFilter.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onChangeFunction: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
};

export default RadioFilter;
