import AccountAdminSelect from 'components/Header/AdminAccountSelect';
import { connect } from 'react-redux';
import {
  hasRole,
  getUserData,
  ROLE_RISK_USER,
  ROLE_GLP_ADMIN
} from 'utils/auth';
import withUserProfile from 'containers/withUserProfile';
import { compose } from 'redux';
import rest from 'redux/modules/rest';
import { setSelectedClientId } from 'reducers/dataStoreReducer';

export const loadClients = () => dispatch => dispatch(rest.actions.clients());

export const selectClient = clientId => dispatch =>
  dispatch(setSelectedClientId(clientId));

const mapStateToProps = state => {
  const user = getUserData();
  const isAdmin = hasRole(user, ROLE_GLP_ADMIN);
  const { client_id } = user;

  return {
    clientsLoading: state.clients.loading,
    clients: (!state.clients.loading && state.clients.data.data) || false,
    selectedClientId: (isAdmin && state.dataStore.selectedClientId) || client_id
  };
};

const mapDispatchToProps = {
  loadClients,
  selectClient
};

const enhance = compose(
  withUserProfile,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(AccountAdminSelect);
