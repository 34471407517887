import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import MultiValueLabel from 'components/MultiValueLabel';
import { sortBy } from 'underscore';

class Filter extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.state = {
      selectedOptions: [],
      currentOptions: null
    };
  }

  onChange(selectedOption) {
    const { onFilter, name } = this.props;

    this.setState(
      {
        selectedOptions: selectedOption
      },
      () => {
        onFilter(name, selectedOption.map(element => element.value));
      }
    );
  }

  setOptions = newOptions => {
    this.setState({ currentOptions: newOptions });
  };

  sortOptions = () => {
    const { currentOptions } = this.state;
    return sortBy(currentOptions, function(optionItem) {
      return optionItem.label;
    });
  };

  render() {
    const { selectedOptions, currentOptions } = this.state;
    const { options, title } = this.props;

    if (currentOptions === null || currentOptions !== options) {
      this.setOptions(options);
    }

    return (
      <div className="order-filters__container">
        <h6>{title}</h6>
        <Select
          components={{ MultiValueLabel: MultiValueLabel }}
          classNamePrefix="filter-select"
          isMulti={true}
          hideSelectedOptions={false}
          value={selectedOptions}
          options={this.sortOptions()}
          onChange={this.onChange}
          placeholder={'All'}
          className={'react-select-custom'}
        />
      </div>
    );
  }
}

Filter.propTypes = {
  options: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default Filter;
