import 'isomorphic-fetch';
import reduxApi from 'redux-api';
import { customFetch, options } from './rest';

const rest = reduxApi({
  dataSource1: {
    url: 'api/datasources/dataSource1/(:date)/(:orderId)',
    options: {
      method: 'get'
    }
  },
  dataSource2: {
    url: 'api/datasources/dataSource2/(:date)/(:orderId)',
    options: {
      method: 'get'
    }
  },
  dataSource3: {
    url: 'api/datasources/dataSource3/(:date)/(:orderId)',
    options: {
      method: 'get'
    }
  },
  dataSource4: {
    url: 'api/datasources/dataSource4/(:date)/(:orderId)',
    options: {
      method: 'get'
    }
  },
  dataSource5: {
    url: 'api/datasources/dataSource5/(:date)/(:orderId)',
    options: {
      method: 'get'
    }
  },
  dataSource7: {
    url: 'api/datasources/dataSource7/(:date)/(:orderId)',
    options: {
      method: 'get'
    }
  },
  dataSource8: {
    url: 'api/datasources/dataSource8/(:date)/(:clientId)',
    options: {
      method: 'get'
    }
  },
  dataSource9: {
    url: 'api/datasources/dataSource9/(:date)/(:clientId)',
    options: {
      method: 'get'
    }
  },
  dataSource10: {
    url: 'api/datasources/dataSource10/(:date)/(:orderId)',
    options: {
      method: 'get'
    }
  }
}).init(customFetch(fetch), false, process.env.REACT_APP_SOCKET_BASE_URL);

rest.use('options', options);

export default rest;
