import { sortBy } from 'underscore';
import { DATA_SOURCE_EIGHT } from 'utils/constants';

export const APPEND_RECORD = 'APP/APPEND_RECORD';
export const APPEND_RECORDS = 'APP/APPEND_RECORDS';
export const RESET_RECORDS = 'APP/RESET_RECORDS';
export const INIT_RECORDS = 'APP/INIT_RECORDS';
export const SORT_DATASOURCE = 'APP/SORT_DATASOURCE';
export const SET_SORT_DIRECTION = 'APP/SET_SORT_DIRECTION';
export const GET_SORT_DIRECTION = 'APP/GET_SORT_DIRECTION';
export const SET_SORT_COLUMN = 'APP/SET_SORT_COLUMN';
export const GET_SORT_COLUMN = 'APP/GET_SORT_COLUMN';
export const SET_SELECTED_CLIENT_ID = 'APP/SET_SELECTED_CLIENT_ID';

export const setSortColumn = sortColumn => ({
  type: SET_SORT_COLUMN,
  payload: sortColumn
});

export const getSortColumn = () => ({
  type: GET_SORT_COLUMN
});

export const setSortDirection = sortDirection => ({
  type: SET_SORT_DIRECTION,
  payload: sortDirection
});

export const getSortDirection = () => ({
  type: GET_SORT_DIRECTION
});

export const setSelectedClientId = selectedClientId => ({
  type: SET_SELECTED_CLIENT_ID,
  payload: selectedClientId
});

export const appendDatasourceRecord = (record, dataSource) => ({
  type: APPEND_RECORD,
  method: dataSource,
  payload: record
});

export const appendDatasourceRecords = (records, dataSource) => ({
  type: APPEND_RECORDS,
  method: dataSource,
  payload: records
});

export const initDatasourceRecords = (date, clientId, records, dataSource) => ({
  type: INIT_RECORDS,
  method: dataSource,
  payload: {
    date,
    clientId,
    records
  }
});

export const sortDataSource = dataSource => ({
  type: SORT_DATASOURCE,
  method: dataSource
});

export const resetDataStore = () => ({
  type: RESET_RECORDS
});

const initialState = {
  dataSource1: {
    date: null,
    id: null,
    data: [],
    loaded: false
  },
  dataSource3: {
    date: null,
    id: null,
    data: [],
    loaded: false
  },
  dataSource4: {
    date: null,
    id: null,
    data: [],
    loaded: false
  },
  dataSource5: {
    date: null,
    id: null,
    data: [],
    loaded: false
  },
  dataSource7: {
    date: null,
    id: null,
    data: [],
    loaded: false
  },
  dataSource8: {
    date: null,
    id: null,
    data: [],
    loaded: false
  },
  dataSource9: {
    date: null,
    id: null,
    data: {},
    loaded: false
  },
  dataSource10: {
    date: null,
    id: null,
    data: {},
    loaded: false
  },
  sortDirection: null,
  sortColumn: null,
  selectedClientId: null
};

const dataSorter = (data, sortDirection, sortColumn) => {
  if (sortDirection == 'ASC') {
    data = sortBy(data, sortColumn);
  } else if (sortDirection == 'DESC') {
    data = sortBy(data, sortColumn).reverse();
  }

  return data;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APPEND_RECORD:
      return {
        ...state,
        [action.method]: {
          ...state[action.method],
          data: [...state[action.method].data, action.payload]
        }
      };

    case APPEND_RECORDS:
      return {
        ...state,
        [action.method]: {
          ...state[action.method],
          data: [...state[action.method].data, ...action.payload],
          loaded: true
        }
      };

    case RESET_RECORDS:
      return Object.assign({}, initialState);

    case INIT_RECORDS:
      return {
        ...state,
        [action.method]: {
          ...state[action.method],
          date: action.payload.date,
          id: action.payload.orderId || action.payload.clientId,
          data:
            action.method === DATA_SOURCE_EIGHT
              ? dataSorter(
                  action.payload.records,
                  state.sortDirection,
                  state.sortColumn
                )
              : action.payload.records,
          loaded: true
        }
      };

    case SORT_DATASOURCE:
      return {
        ...state,
        [action.method]: {
          ...state[action.method],
          data: dataSorter(
            state[action.method].data,
            state.sortDirection,
            state.sortColumn
          )
        }
      };

    case SET_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: action.payload
      };
    case GET_SORT_DIRECTION:
      return {
        ...state
      };

    case SET_SORT_COLUMN:
      return {
        ...state,
        sortColumn: action.payload
      };

    case GET_SORT_COLUMN:
      return {
        ...state
      };

    case SET_SELECTED_CLIENT_ID:
      return {
        ...state,
        selectedClientId: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
