import React from 'react';
import './PageTitle.css';
import PropTypes from 'prop-types';

function PageTitle({ title, subTitle, children }) {
  return (
    <div className="page-title">
      <h2>{title}</h2>
      {subTitle && <h4>{subTitle}</h4>}
      {children}
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default PageTitle;
