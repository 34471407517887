import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import Confirm from '../components/Confirm';
import { reset as appReset } from 'routes/Risk/reducer';

const mapStateToProps = state => ({
  initialValues: state.accountService.initialFormValues,
  newValues: state.accountService.newFormValues,
  accounts: state.accountService.accounts,
  riskTypes: state.accountService.riskTypes,
  limits: state.accountService.brokerLimits
});

const mapDispatchToProps = dispatch => ({
  appReset: () => dispatch(appReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAlert(Confirm));
