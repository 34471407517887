import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from '../../../components/PrivateRoute';
import ChangePasswordWrapper from '../routes/ChangePassword/containers';
import ResultMessage from '../../../components/ResultMessage';

function Profile({ match }) {
  return (
    <Switch>
      <PrivateRoute
        exact
        key="change_password"
        path={`${match.path}/change-password`}
        component={ChangePasswordWrapper}
      />
      <Route
        exact
        key="password_change_success"
        path="/profile/change-password/success"
        render={() => (
          <ResultMessage
            helmet="Change Successful"
            title="Change Password"
            header="Success!"
            body="Your password has been changed successfully."
            actionLinkUrl="/"
            actionLinkText="Return Home"
          />
        )}
      />
    </Switch>
  );
}

export default Profile;
