import rest from 'redux/modules/dataSourceRest';
import { initDatasourceRecords } from 'reducers/dataStoreReducer';

const dataStoreMiddleware = store => next => action => {
  let keys = Object.keys(rest.events);

  keys.forEach(key => {
    if (
      action.type === rest.events[key].actionSuccess &&
      action.data &&
      action.data.data
    ) {
      const { date, clientId, orderId } = action.request.pathvars;

      store.dispatch(
        initDatasourceRecords(date, clientId || orderId, action.data.data, key)
      );
    }
  });

  return next(action);
};

export default dataStoreMiddleware;
