import React, { Component } from 'react';
import Select from 'react-select';
import { filter, isEmpty } from 'underscore';
import { Route, Switch } from 'react-router';
import { NavItem, Nav } from 'reactstrap';
import { isArray } from 'underscore';

class AdminAccountSelect extends Component {
  onchange = ({ value }) => {
    const { selectClient } = this.props;

    selectClient(value);
  };

  render() {
    const {
      clientsLoading,
      clients,
      loadClients,
      selectedClientId
    } = this.props;

    if (!clientsLoading && clients === false) {
      loadClients();
    }

    const options = filter(clients, ({ clientId }) => !isEmpty(clientId)).map(
      ({ clientId: value, fullName: label }) => ({
        value,
        label
      })
    );

    const selectedOption =
      selectedClientId &&
      filter(options, ({ value }) => selectedClientId == value);

    return (
      <Switch>
        <Route exact key="orders_overview_dropdown" path="/orders/:date">
          <div className="admin-account-select">
            {clients && (
              <Select
                options={options}
                onChange={this.onchange}
                value={selectedOption}
              />
            )}
          </div>
        </Route>
        <Route key="orders_catch_all" path="/(orders|risk)">
          {isArray(selectedOption) &&
            selectedOption.length > 0 && (
              <Nav className="admin-nav admin-current-account">
                <NavItem>
                  <div className="client-name-label">
                    Client: {selectedOption[0].label}
                  </div>
                </NavItem>
              </Nav>
            )}
        </Route>
      </Switch>
    );
  }
}

export default AdminAccountSelect;
