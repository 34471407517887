import rest from 'redux/modules/rest';
import {getUserData} from "../utils/auth";

export const loadUser = (id, resolve) => {
  return dispatch => {
    dispatch(rest.actions.user.get({ id }, resolve));
  };
};

export const loadMyUser = resolve => {
  const userData = getUserData();

  return loadUser(userData.user_id, resolve);
};

