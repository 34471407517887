import React from 'react';
import {
  Navbar,
  NavbarBrand,
  NavItem,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import PropTypes from 'prop-types';
import './Header.css';
import logo from './glplogo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import AdminAccountSelect from 'containers/Header/AdminAccountSelectContainer';

function Header({
  isAuthenticated,
  hasRiskAccess,
  isGlpAdmin,
  clientName,
  clientId
}) {
  return (
    <Navbar expand="md">
      <NavbarBrand href="/">
        <img alt="logo" className="logo" src={logo} />
      </NavbarBrand>
      {isAuthenticated && (
        <React.Fragment>
          <Nav className="ml-auto" navbar>
            {(isGlpAdmin || clientId === 'CODA') && (
              <NavItem>
                <Link to="/orders/nroute">NRoute</Link>
              </NavItem>
            )}
            <NavItem>
              <Link to="/orders/today">Today's Orders</Link>
            </NavItem>
            {hasRiskAccess && (
              <NavItem>
                <Link to="/risk">Risk Portal</Link>
              </NavItem>
            )}
            <NavItem>
              <Link to="/orders/analysis">Order Analysis</Link>
            </NavItem>
          </Nav>
          {(isGlpAdmin && (
            <Nav className="admin-nav">
              <NavItem>
                <AdminAccountSelect />
              </NavItem>
            </Nav>
          )) ||
            (clientName && (
              <Nav className="admin-nav admin-current-account">
                <NavItem>
                  <div className="client-name-label">Client: {clientName}</div>
                </NavItem>
              </Nav>
            ))}
          <UncontrolledDropdown inNavbar className="header-profile">
            <DropdownToggle nav caret>
              <FontAwesomeIcon className="user-circle" icon={faUserCircle} />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link to="/profile/change-password/">Change Password</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/logout">Logout</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      )}
    </Navbar>
  );
}

Header.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  hasRiskAccess: PropTypes.bool.isRequired,
  isGlpAdmin: PropTypes.bool.isRequired,
  clientName: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired
};

export default Header;
