import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Filter from './Filter';
import { Row, Col } from 'reactstrap';
import './OrderFilters.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class OrderFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        Trader: [],
        Symbol: [],
        Account: [],
        Algorithm: [],
        Status: []
      }
    };

    this.handleFilter = this.handleFilter.bind(this);
  }

  handleFilter(name, filters) {
    const { onFilter } = this.props;

    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: filters
        }
      },
      () => {
        onFilter(this.state.filters);
      }
    );
  }

  render() {
    const { closeSideBar } = this.props;

    return (
      <div>
        <Row className="order-filters no-gutters">
          <div className="order-filters__container">
            <div className="filter-header-container">
              <span className="filters-header">Dashboard Filters</span>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => closeSideBar(false)}
                className="hide-sidebar-button"
              />
            </div>
          </div>
        </Row>
        <hr />
        <Row className="order-filters no-gutters">
          <Filter
            options={this.getUniqueOptions('Trader')}
            title="Trader"
            name="Trader"
            onFilter={this.handleFilter}
          />
        </Row>
        <Row className="order-filters no-gutters">
          <Filter
            options={this.getUniqueOptions('Symbol')}
            title="Symbol"
            name="Symbol"
            onFilter={this.handleFilter}
          />
        </Row>
        <Row className="order-filters no-gutters">
          <Filter
            options={this.getUniqueOptions('Account')}
            title="Account"
            name="Account"
            onFilter={this.handleFilter}
          />
        </Row>
        <Row className="order-filters no-gutters">
          <Filter
            options={this.getUniqueOptions('StrategyName')}
            title="Algorithm"
            name="Algorithm"
            onFilter={this.handleFilter}
          />
        </Row>
        <Row className="order-filters no-gutters">
          <Filter
            options={this.getUniqueOptions('Status')}
            title="Status"
            name="Status"
            onFilter={this.handleFilter}
          />
        </Row>
      </div>
    );
  }

  getUniqueOptions(property) {
    const { orders } = this.props;

    return _.uniq(orders, element => element[property]).map(element => ({
      value: element[property],
      label: element[property]
    }));
  }
}

OrderFilters.propTypes = {
  orders: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  closeSideBar: PropTypes.func.isRequired
};

export default OrderFilters;
