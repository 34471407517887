import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';

class RiskHeader extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { title, id, helpText, className } = this.props;

    return (
      <Col sm className={`header risk-header ${className}`}>
        <div>
          {helpText !== undefined ? (
            <span id={id}>
              <div>
                {title}
                <FontAwesomeIcon
                  className="risk-header-help-button"
                  icon={faInfoCircle}
                />
                <Tooltip
                  placement="bottom"
                  isOpen={this.state.tooltipOpen}
                  target={id}
                  toggle={this.toggle}
                >
                  {helpText}
                </Tooltip>
              </div>
            </span>
          ) : (
            <span>{title}</span>
          )}
        </div>
      </Col>
    );
  }
}

RiskHeader.propTypes = {
  title: PropTypes.string.isRequired,
  helpText: PropTypes.string
};

export default RiskHeader;
