import Login from '../components/Login';
import { reduxForm, SubmissionError } from 'redux-form';
import { FORM_LOGIN } from 'utils/constants';
import rest from 'redux/modules/rest';
import {
  setToken,
  setRefreshToken,
  clearSessionStorage,
  hasRole,
  ROLE_CLIENT_USER
} from 'utils/auth';
import { push } from 'react-router-redux';
import { loadMyUser } from 'reducers/User';

const loginErrorMessage =
  'The Email And/Or Password You Have Entered Is Incorrect. Please Try Again.';

function onSubmit(values, dispatch) {
  const params = { body: JSON.stringify(values) };

  return new Promise((resolve, reject) => {
    dispatch(
      rest.actions.login(null, params, (err, data) => {
        if (err) {
          reject(new SubmissionError({ _error: loginErrorMessage }));
          return;
        }
        setToken(data.token);
        setRefreshToken(data.refresh_token);

        dispatch(
          loadMyUser((err, user) => {
            if (err || !hasRole(user, ROLE_CLIENT_USER)) {
              clearSessionStorage();
              reject(new SubmissionError({ _error: loginErrorMessage }));
              return;
            }

            dispatch(push('/'));
            resolve(data);
          })
        );
      })
    );
  });
}

export default reduxForm({
  form: FORM_LOGIN,
  onSubmit
})(Login);
