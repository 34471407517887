import React, { Component } from 'react';
import { isAuthenticated } from 'utils/auth';
import { Redirect } from 'react-router-dom';

class Index extends Component {
  render() {
    if (!isAuthenticated()) {
      return <Redirect to="/login" />;
    }

    return <Redirect to="/orders/today" />;

    //TODO: Later Feature for Home Page/Notifications
    return <div />;
  }
}

Index.propTypes = {};

export default Index;
