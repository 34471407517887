import io from 'socket.io-client';
import { getToken } from 'utils/auth';

export const SOCKET_IO_URL = process.env.REACT_APP_SOCKET_URL || false;

export function createSocket(pageNamespace) {
  const { REACT_APP_SOCKET_BASE_URL } = process.env;
  const socket = io(`${REACT_APP_SOCKET_BASE_URL}/${pageNamespace}`);

  socket.on('connect', function() {
    socket.emit('authenticate', getToken());
  });

  socket.on('authentication_expiring', () => {
    socket.emit('reauthenticate', getToken());
  });

  return socket;
}
