import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PageTitle from 'components/PageTitle';
import {
  formatValue,
  FIELD_TYPE_DOLLAR,
  getRecentQuarter,
  getNextQuarter
} from 'utils/formatter';
import { Row, Col } from 'reactstrap';
import PricesGraph from './Graphs/PricesGraph';
import './Graphs.css';
import LastMarketGraph from './Graphs/LastMarketGraph';
import LiquidityModeGraph from './Graphs/LiquidityModeGraph';
import LoadingIndicator from 'components/LoadingIndicator';
import { getUserData } from 'utils/auth';
import { find } from 'underscore';
import moment from 'moment';
import { createSocket } from 'reducers/socketIO';
import { Link } from 'react-router-dom';
import DataListContainer from 'components/DataGrid/containers/DataListContainer';

const dataSources = [
  'dataSource1',
  'dataSource3',
  'dataSource4',
  'dataSource5',
  'dataSource7',
  'dataSource8'
];

const columns = [
  {
    key: 'SentDate',
    name: 'Sent Date',
    sortable: true,
    width: 200
  },
  {
    key: 'OrderId',
    name: 'Client Order Id',
    sortable: true,
    width: 250
  },
  {
    key: 'ActivityTime',
    name: 'Activity Time',
    sortable: true,
    width: 250
  },
  {
    key: 'ExecutionVolume',
    name: 'Execution Volume',
    sortable: true,
    width: 200
  },
  {
    key: 'ExecutionPrice',
    name: 'Execution Price',
    sortable: true,
    width: 150,
    formatter: ({ value }) => {
      return formatValue(value, FIELD_TYPE_DOLLAR, 2, 2);
    }
  },
  {
    key: 'LastMkt',
    name: 'Last Mkt',
    sortable: true,
    width: 150
  },
  {
    key: 'MarketType',
    name: 'Market Type',
    sortable: true,
    width: 150
  },
  {
    key: 'LiquidityType',
    name: 'Liquidity Type',
    sortable: true,
    width: 150
  }
];

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: null
    };
  }

  componentDidMount() {
    const {
      resetDataStore,
      loadDataSource,
      resetAll,
      dataSource8,
      loadDataSource8,
      appendDatasourceRecords,
      initDatasourceRecords,
      match: {
        params: { date, order_id }
      }
    } = this.props;

    const { client_id } = getUserData();

    if (!dataSource8.loaded) {
      loadDataSource8(date, client_id);
    }

    resetAll();
    resetDataStore();
    loadDataSource(date, order_id);

    let today = moment();

    if (date === today.format('YYYY-MM-DD')) {
      const socket = (this.socket = createSocket('detailsPage'));

      socket.on('authenticated', () => {
        socket.emit('join-room', {
          date,
          orderId: order_id
        });

        dataSources.forEach(dataSource => {
          socket.on(dataSource, results => {
            appendDatasourceRecords(results, dataSource);
          });
        });

        socket.on('dataSource8', results => {
          initDatasourceRecords(date, client_id, results, 'dataSource8');
        });
      });

      socket.on('disconnect', function() {
        dataSources.forEach(dataSource => {
          socket.off(dataSource);
        });
        socket.off('dataSource8');
      });
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    let newState = {
      ...previousState,
      ...nextProps
    };

    if (previousState.order === null && nextProps.dataSource8.loaded) {
      newState = Object.assign({}, newState, {
        order: find(
          nextProps.dataSource8.data,
          row => row.UniqueOrderId === nextProps.match.params.order_id
        )
      });
    }

    return newState;
  }

  handleOrderClick = () => {};

  render() {
    const {
      dataSource1,
      dataSource4,
      dataSource5,
      dataSource7,
      loaded,
      timeFrame,
      match: {
        params: { date }
      }
    } = this.props;

    const { order } = this.state;

    if (!order) {
      return <LoadingIndicator />;
    }

    const price =
      order.LimitPrice === 0 || order.LimitPrice === null
        ? 'MKT'
        : `${formatValue(order.LimitPrice, FIELD_TYPE_DOLLAR, 2)}`;

    return (
      <div className="order-details">
        <Helmet>
          <title>Order Details</title>
        </Helmet>
        <Link to={`/orders/${date}`}>Back to {date}</Link>
        <PageTitle
          title={`${order.Side}
          ${formatValue(order.OrderQty)}
           ${order.Symbol}
           @ ${price} (${formatValue(order.ExecutedShares)} @ ${formatValue(
            order.AvgPrice,
            FIELD_TYPE_DOLLAR
          )} - ${order.Status})`}
        />
        <Row className="prices-graphs">
          <Col sm={12}>
            {!loaded && <LoadingIndicator />}
            {loaded && (
              <div>
                <Row>
                  <Col sm={12}>
                    <PricesGraph
                      title="Prices"
                      {...this.props}
                      dataSource1={dataSource1}
                      dataSource4={dataSource4}
                      dataSource5={dataSource5}
                      dataSource7={dataSource7}
                      getNextQuarter={getNextQuarter}
                      getRecentQuarter={getRecentQuarter}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <LastMarketGraph
                      title="Last Market"
                      timeFrame={timeFrame}
                      dataSource1={dataSource1.data}
                    />
                  </Col>
                  <Col sm={6}>
                    <LiquidityModeGraph
                      title="Liquidity Mode"
                      timeFrame={timeFrame}
                      dataSource1={dataSource1.data}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
        <DataListContainer
          onValueClick={this.handleOrderClick}
          dataKeyForOnClick=""
          routeKeyForOnClick=""
          dateColumnKey=""
          data={dataSource1.data}
          columns={columns}
          date={date}
        />
      </div>
    );
  }
}

export default Orders;
