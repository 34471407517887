import RequestPasswordEmailComponent from '../components';
import { reduxForm, SubmissionError } from 'redux-form';
import { FORM_REQUEST_RESET } from 'utils/constants';
import rest from 'redux/modules/rest';
import { push } from 'react-router-redux';

function onSubmit(values, dispatch) {
  const params = { body: JSON.stringify(values) };

  let myOptions = null;

  return new Promise((resolve, reject) => {
    dispatch(
      rest.actions.requestPassResetEmail(myOptions, params, (err, data) => {
        if (err) {
          reject(new SubmissionError({ _error: err.statusText }));
          return;
        }

        if (data.error) {
          reject(new SubmissionError({ _error: data.error }));
          return;
        }

        dispatch(push('/resetting/request/email-sent'));
      })
    );
  });
}

export default reduxForm({
  form: FORM_REQUEST_RESET,
  onSubmit
})(RequestPasswordEmailComponent);
