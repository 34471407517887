import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { formatValue } from 'utils/formatter';
import ReasonForChange from './ReasonForChange';
import ClientNameContainer from '../../../containers/ClientNameContainer';
import '../Confirm.css';

function ConfirmationForm({ values, onCancel, handleSubmit, onSubmit }) {
  return (
    <Col md={{ size: 8, offset: 2 }} className="confirm-page">
      <ClientNameContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="headers">
          <Row>
            <Col className="header left" sm={2}>
              Account
            </Col>
            <Col className="header" sm={3}>
              Risk Type
            </Col>
            <Col className="header" sm={2}>
              Old Value
            </Col>
            <Col className="header" sm={2}>
              New Value
            </Col>
            <Col className="header" sm={3}>
              Message
            </Col>
          </Row>
        </div>
        <div className="accounts">
          {values.map((element, key) => {
            return (
              <div key={key} className="account">
                <Row>
                  <Col className="col left" sm={2}>
                    {element.account.name}
                  </Col>
                  <Col className="col" sm={3}>
                    {element.riskType.name}
                  </Col>
                  <Col className="col" sm={2}>
                    {formatValue(
                      element.oldValue,
                      element.riskType.number_type
                    )}
                  </Col>
                  <Col className="col" sm={2}>
                    {formatValue(
                      element.newValue,
                      element.riskType.number_type
                    )}
                  </Col>
                  <Col sm={3} className="col risk-alert left">
                    {element.alert}
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>

        <div className="extras">
          <Row>
            <Col className="left" sm={12}>
              <div className="header left">Reason for Change</div>
              <div className="reason-for-change">
                <ReasonForChange />
              </div>
            </Col>
          </Row>
        </div>

        <div className="actions">
          <Row>
            <Col sm={12} className="text-right">
              <Button onClick={onCancel} color="danger">
                Cancel
              </Button>
              <Button color="default">Save</Button>
            </Col>
          </Row>
        </div>
      </form>
    </Col>
  );
}

ConfirmationForm.propTypes = {
  values: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ConfirmationForm;
