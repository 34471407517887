import React from 'react';
import Auth from 'components/Auth';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from 'components/Input';
import { Form, Button, Alert } from 'reactstrap';
import { email, required } from 'utils/validators';

function RequestPasswordEmail({ handleSubmit, error }) {
  return (
    <Auth title="Password Reset">
      <div className="password-interior-container">
        <Helmet>
          <title>Request Password Reset Email</title>
          <body className="login-background" />
        </Helmet>
        {error && <Alert color="danger">{error}</Alert>}
        <div className="password-text-container">
          <h2>Forgot Your Password?</h2>
          <p>
            Enter your registered email below and we will send you instructions
            to reset your password.
          </p>
        </div>
        <div className="password-form-container">
          <Form onSubmit={handleSubmit}>
            <Field
              id="login_username"
              name="username"
              type="text"
              component={Input}
              placeholder="Email"
              label=""
              validate={[required, email]}
            />
            <Button type="submit">Request Reset Link</Button>
            <div className="return-home-link">
              <a href="/">Back to login</a>
            </div>
          </Form>
        </div>
      </div>
    </Auth>
  );
}

RequestPasswordEmail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RequestPasswordEmail;
