import React from 'react';
import Auth from 'components/Auth';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from 'components/Input';
import { Form, Button, Alert } from 'reactstrap';
import {
  password,
  required,
  passwordsMatch,
  minLength8
} from 'utils/validators';

function ResetPassword({ handleSubmit, error }) {
  return (
    <Auth title="Password Reset">
      <div className="password-interior-container">
        <Helmet>
          <title>Password Reset</title>
          <body className="login-background" />
        </Helmet>
        {error && <Alert color="danger">{error}</Alert>}
        <div className="password-text-container">
          <h2>Enter New Password</h2>
        </div>
        <div className="password-form-container">
          <Form onSubmit={handleSubmit}>
            <Field
              id="reset_password"
              name="password"
              type="password"
              component={Input}
              placeholder="Password"
              label=""
              validate={[required, password, passwordsMatch, minLength8]}
            />
            <Field
              id="reset_password_confirm"
              name="password_confirm"
              type="password"
              component={Input}
              placeholder="Confirm Password"
              label=""
              validate={[required, password, passwordsMatch, minLength8]}
            />
            <Button type="submit">Save Password</Button>
          </Form>
        </div>
      </div>
    </Auth>
  );
}

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ResetPassword;
