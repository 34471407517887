import { connect } from 'react-redux';
import { logoutUser } from 'reducers';
import { push } from 'react-router-redux';
import Logout from '../components/Logout';

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logoutUser());
    dispatch(push('/login'));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(Logout);
