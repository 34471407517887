import React from 'react';
import { components } from 'react-select';

function MultiValueLabel(props) {
  if (
    props &&
    props.selectProps &&
    props.selectProps.value &&
    props.selectProps.value.length > 0
  ) {
    if (props.selectProps.value.length == 1) {
      return <components.MultiValueLabel {...props} />;
    } else {
      return (
        <div className="multi-select-label">Multiple filters selected</div>
      );
    }
  }

  return <components.MultiValueLabel {...props} />;
}

MultiValueLabel.propTypes = {};

export default MultiValueLabel;
