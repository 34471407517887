import { connect } from 'react-redux';
import DataList from '../components/DataList';
import {
  setSortDirection,
  setSortColumn,
  sortDataSource
} from 'reducers/dataStoreReducer';

const mapStateToProps = state => ({
  sortDirection: state.dataStore.sortDirection,
  sortColumn: state.dataStore.sortColumn
});

const mapDispatchToProps = dispatch => ({
  setSortDirection: sortDirection => dispatch(setSortDirection(sortDirection)),
  setSortColumn: sortColumn => dispatch(setSortColumn(sortColumn)),
  sortDataSource: dataSource => dispatch(sortDataSource(dataSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataList);
