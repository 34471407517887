import React from 'react';
import PropTypes from 'prop-types';
import Limit from './Limit';
import { Col, Row } from 'reactstrap';

function Limits({ limits }) {
  return (
    <div className="limits">
      <Row>
        <Col sm />
        {limits.map((limit, key) => {
          return <Limit limit={limit} key={key} />;
        })}
      </Row>
    </div>
  );
}

Limits.propTypes = {
  limits: PropTypes.array.isRequired
};

export default Limits;
