import ResetPasswordComponent from '../components';
import { reduxForm, SubmissionError } from 'redux-form';
import { FORM_RESET_PASSWORD } from 'utils/constants';
import rest from 'redux/modules/rest';

import { push } from 'react-router-redux';

function onSubmit(values, dispatch, props) {
  const requestData = Object.assign({}, values, {
    userToken: props.match.params.userToken
  });
  const params = { body: JSON.stringify(requestData) };

  return new Promise((resolve, reject) => {
    dispatch(
      rest.actions.submitNewPassword(null, params, (err, data) => {
        if (err) {
          reject(new SubmissionError({ _error: err.statusText }));
          return;
        }

        if (data.errors) {
          reject(new SubmissionError({ _error: data.errors[0]['message'] }));
          return;
        }

        if (data.error) {
          reject(new SubmissionError({ _error: data.error }));
          return;
        }

        dispatch(push('/resetting/reset/success'));
      })
    );
  });
}

export default reduxForm({
  form: FORM_RESET_PASSWORD,
  onSubmit
})(ResetPasswordComponent);
