import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Popover, PopoverBody } from 'reactstrap';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';

class DateSelector extends Component {
  constructor(props) {
    super(props);

    let firstDate = new Date();
    firstDate.setMonth(firstDate.getMonth() - 6);

    this.state = {
      selectDateOpen: false,
      firstDate
    };
  }

  toggleDateSelector = () => {
    this.setState({
      selectDateOpen: !this.state.selectDateOpen
    });
  };

  onSelect = date => {
    const { onDateSelect } = this.props;

    this.toggleDateSelector();

    onDateSelect(date);
  };

  render() {
    const { selectDateOpen, firstDate } = this.state;
    const { date, children } = this.props;

    return (
      <Fragment>
        <FontAwesomeIcon
          icon={faCalendarAlt}
          onClick={this.toggleDateSelector}
          id="date-selector"
        />
        <Popover
          isOpen={selectDateOpen}
          toggle={this.toggleDateSelector}
          target="date-selector"
          placement="bottom"
          className="order-calendar-select"
        >
          <PopoverBody>
            <Calendar
              onChange={this.onSelect}
              value={new Date(date.format())}
              minDate={firstDate}
            />
            {children}
          </PopoverBody>
        </Popover>
      </Fragment>
    );
  }
}

DateSelector.propTypes = {
  date: PropTypes.object.isRequired,
  onDateSelect: PropTypes.func.isRequired,
  children: PropTypes.element
};

export default DateSelector;
