import React from 'react';

import { AbstractSeries } from 'react-vis';

const predefinedClassName =
  'rv-xy-plot__series rv-xy-plot__series--candlestick';

const xDistance = [{ x: 60000 }, { x: 0 }];

class CandlestickSeries extends AbstractSeries {
  render() {
    const { className, data, marginLeft, marginTop } = this.props;
    if (!data) {
      return null;
    }

    const xFunctor = this._getAttributeFunctor('x');
    const yFunctor = this._getAttributeFunctor('y');

    const colors = this._getAttributeFunctor('colors')([]);

    const strokeFunctor =
      this._getAttributeFunctor('stroke') || this._getAttributeFunctor('color');

    const fillFunctor =
      this._getAttributeFunctor('fill') || this._getAttributeFunctor('color');

    const opacityFunctor = this._getAttributeFunctor('opacity');

    const distance =
      Math.abs(xFunctor(xDistance[0]) - xFunctor(xDistance[1])) / 2;

    return (
      <g
        className={`${predefinedClassName} ${className}`}
        transform={`translate(${marginLeft},${marginTop})`}
      >
        {data.map((d, i) => {
          const xTrans = xFunctor(d);
          // Names of values borrowed from here https://en.wikipedia.org/wiki/Candlestick_chart
          const yHigh = yFunctor({ ...d, y: d.yHigh });
          const yOpen = yFunctor({ ...d, y: d.yOpen });
          const yClose = yFunctor({ ...d, y: d.yClose });
          const yLow = yFunctor({ ...d, y: d.yLow });

          const closedHigher = d.yClose >= d.yOpen;
          const color = colors[+closedHigher];

          const lineAttrs = {
            stroke: color
          };

          return (
            <g
              transform={`translate(${xTrans})`}
              opacity={opacityFunctor ? opacityFunctor(d) : 1}
              key={i}
              onClick={e => this._valueClickHandler(d, e)}
              onMouseOver={e => this._valueMouseOverHandler(d, e)}
              onMouseOut={e => this._valueMouseOutHandler(d, e)}
            >
              <line x1={0} x2={0} y1={yHigh} y2={yLow} {...lineAttrs} />
              <rect
                x={-distance}
                width={Math.max(distance * 2, 0)}
                y={yOpen}
                height={Math.abs(yOpen - yClose)}
                fill={color}
              />
            </g>
          );
        })}
      </g>
    );
  }
}

CandlestickSeries.displayName = 'CandlestickSeries';

export default CandlestickSeries;
