import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parseActivityTime } from '../PricesGraph/index';
import VolumeCurveGraph1 from './VolumeCurveGraph1';
import VolumeCurveGraph2 from './VolumeCurveGraph2';
import GraphFilter from 'components/GraphFilter';
import withGraph from 'components/withGraph';

const colors = [
  '#bdc3c7',
  '#26f4ba',
  '#07748e',
  '#59b89e',
  '#19326e',
  '#00536c'
];

class VolumeCurveGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource1: [],
      dataSource1_executionVolume: [],
      dataSource1_provideQnty: [],
      dataSource1_aggrQty: [],
      dataSource3: [],
      dataSource3_nominalTarget: [],
      dataSource3_lowTarget: [],
      dataSource3_highTarget: [],
      y0Axis: [0, 0],
      y1Axis: [0, 0],
      graphState: {
        executionVolume: true,
        provideQnty: true,
        aggrQty: true,
        nominalTarget: true,
        lowTarget: true,
        highTarget: true
      }
    };

    this.onFilter = this.onFilter.bind(this);
  }

  static generateSourceData(
    dataSource,
    previousStateDataSource,
    yPoints,
    property
  ) {
    return dataSource.map(row => {
      let activityTime = parseActivityTime(row.ActivityTime);

      if (
        yPoints.lowestPoint === null ||
        (row[property] < yPoints.lowestPoint && row[property] !== 0)
      ) {
        yPoints.lowestPoint = row[property];
      }

      if (row[property] > yPoints.highestPoint) {
        yPoints.highestPoint = row[property];
      }

      return {
        x: activityTime,
        y: row[property]
      };
    });
  }

  static getDerivedStateFromProps(nextProps, previousState) {
    const { dataSource3, dataSource1 } = nextProps;

    let newState = {
      ...previousState,
      ...nextProps
    };

    let y0Points = {
      lowestPoint: null,
      highestPoint: 0
    };

    let y1Points = {
      lowestPoint: null,
      highestPoint: 0
    };

    if (dataSource3 && dataSource3.length > previousState.dataSource3) {
      let dataSource3_nominalTarget = VolumeCurveGraph.generateSourceData(
        dataSource3,
        previousState.dataSource3,
        y0Points,
        'Target'
      );
      let dataSource3_lowTarget = VolumeCurveGraph.generateSourceData(
        dataSource3,
        previousState.dataSource3,
        y0Points,
        'LowTarget'
      );
      let dataSource3_highTarget = VolumeCurveGraph.generateSourceData(
        dataSource3,
        previousState.dataSource3,
        y0Points,
        'HighTarget'
      );
      let dataSource1_provideQnty = VolumeCurveGraph.generateSourceData(
        dataSource3,
        previousState.dataSource3,
        y1Points,
        'ProvideQnty'
      );
      let dataSource1_aggrQty = VolumeCurveGraph.generateSourceData(
        dataSource3,
        previousState.dataSource3,
        y1Points,
        'AggrQty'
      );

      newState = Object.assign({}, newState, {
        dataSource3_nominalTarget,
        dataSource3_lowTarget,
        dataSource3_highTarget,
        dataSource1_provideQnty,
        dataSource1_aggrQty,
        y0Axis: [y0Points.lowestPoint, y0Points.highestPoint]
      });
    }

    if (dataSource1 && dataSource1.length > previousState.dataSource1) {
      let dataSource1_executionVolume = VolumeCurveGraph.generateSourceData(
        dataSource1,
        previousState.dataSource1,
        y1Points,
        'ExecutionVolume'
      );

      newState = Object.assign({}, newState, {
        dataSource1_executionVolume,
        y1Axis: [y1Points.lowestPoint, y1Points.highestPoint]
      });
    }

    return newState;
  }

  onFilter(graph) {
    this.setState({
      graphState: {
        ...this.state.graphState,
        [graph]: !this.state.graphState[graph]
      }
    });
  }

  render() {
    const { timeFrame } = this.props;
    const {
      y0Axis,
      y1Axis,
      dataSource3_nominalTarget,
      dataSource3_lowTarget,
      dataSource3_highTarget,
      dataSource1_executionVolume,
      dataSource1_provideQnty,
      dataSource1_aggrQty,
      graphState,
      width
    } = this.state;

    return (
      <div className="graph multi-graph">
        <VolumeCurveGraph2
          timeFrame={timeFrame}
          yAxis={y1Axis}
          executionVolume={{
            data: dataSource1_executionVolume,
            color: colors[0],
            shown: graphState.executionVolume
          }}
          provideQnty={{
            data: dataSource1_provideQnty,
            color: colors[1],
            shown: graphState.provideQnty
          }}
          aggrQty={{
            data: dataSource1_aggrQty,
            color: colors[2],
            shown: graphState.aggrQty
          }}
          width={width}
        />
        <VolumeCurveGraph1
          timeFrame={timeFrame}
          yAxis={y0Axis}
          nominalTarget={{
            data: dataSource3_nominalTarget,
            color: colors[3],
            shown: graphState.nominalTarget
          }}
          lowTarget={{
            data: dataSource3_lowTarget,
            color: colors[4],
            shown: graphState.lowTarget
          }}
          highTarget={{
            data: dataSource3_highTarget,
            color: colors[5],
            shown: graphState.highTarget
          }}
          width={width}
        />
        <div className="legend">
          <div className="items">
            <GraphFilter
              color={colors[3].substr(1, 6)}
              label="Nominal"
              onChange={() => this.onFilter('nominalTarget')}
              shown={graphState.nominalTarget}
            />
            <GraphFilter
              color={colors[4].substr(1, 6)}
              label="Low"
              onChange={() => this.onFilter('lowTarget')}
              shown={graphState.lowTarget}
            />
            <GraphFilter
              color={colors[5].substr(1, 6)}
              label="High"
              onChange={() => this.onFilter('highTarget')}
              shown={graphState.highTarget}
            />
            <GraphFilter
              color={colors[1].substr(1, 6)}
              label="Provide Quantity"
              onChange={() => this.onFilter('provideQnty')}
              shown={graphState.provideQnty}
            />
            <GraphFilter
              color={colors[2].substr(1, 6)}
              label="Aggressive Quantity"
              onChange={() => this.onFilter('aggrQty')}
              shown={graphState.aggrQty}
            />
            <GraphFilter
              color={colors[0].substr(1, 6)}
              label="Print Size"
              onChange={() => this.onFilter('executionVolume')}
              shown={graphState.executionVolume}
            />
          </div>
        </div>
      </div>
    );
  }
}

VolumeCurveGraph.propTypes = {
  dataSource3: PropTypes.array.isRequired
};

export default withGraph(VolumeCurveGraph);
