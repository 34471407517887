import { LOCATION_CHANGE } from 'react-router-redux';
import rest from 'redux/modules/rest';
import {
  getRefreshToken,
  setRefreshToken,
  setToken,
  isAuthenticated
} from 'utils/auth';

const blockedRoutes = ['/login', '/logout', '/resetting'];

const routeSessionUpdater = store => next => action => {
  if (
    isAuthenticated() &&
    action.type === LOCATION_CHANGE &&
    !store.getState().refreshToken.loading &&
    !blockedRoutes.some(value => action.payload.pathname.indexOf(value) === 0)
  ) {
    const params = {
      body: JSON.stringify({ refresh_token: getRefreshToken() })
    };
    store.dispatch(
      rest.actions.refreshToken(null, params, (err, response) => {
        setRefreshToken(response.refresh_token);
        setToken(response.token);
      })
    );
  }

  return next(action);
};

export default routeSessionUpdater;
