import React from 'react';
import PropTypes from 'prop-types';
import Account from './Account';

function Accounts({ accounts }) {
  return (
    <div className="accounts">
      {accounts.map((account, key) => {
        return <Account account={account} key={key} />;
      })}
    </div>
  );
}

Accounts.propTypes = {
  accounts: PropTypes.array.isRequired
};

export default Accounts;
